import Actionnable_Entity from "./actionnable";
export default class Actionnable extends Actionnable_Entity {
  static fromList(actionnablesJSON: unknown): Array<Actionnable> {
    const actionnables: Actionnable[] = [];
    if (actionnablesJSON)
      Array.isArray(actionnablesJSON) &&
        actionnablesJSON.forEach((actionnableJSON) => {
          console.log(actionnableJSON);
          actionnables.push(new Actionnable(actionnableJSON));
        });
    return actionnables;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
