import { useMemo } from "react";
import { Instrument } from "../../entities/instrument";
import {
  useGetInstrumentQuery,
  useGetInstrumentsQuery,
} from "./instrumentEndpoints";

export function useInstrument(
  instrumentID: number | undefined | null,
  opt?: { skip?: boolean }
) {
  const instrumentQuery = useGetInstrumentQuery(instrumentID ?? 0, {
    skip: !instrumentID || opt?.skip,
  });
  const instrumentEntity = instrumentQuery.data;

  return useMemo(() => {
    const ret: typeof instrumentQuery & {
      instrument?: Instrument | undefined;
    } = {
      ...instrumentQuery,
    };
    if (instrumentEntity) ret.instrument = new Instrument(instrumentEntity);
    return ret;
  }, [instrumentEntity]);
}

export function useInstruments() {
  const instrumentQuery = useGetInstrumentsQuery();
  const instrumentEntitiesMap = instrumentQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof instrumentQuery & {
      instruments?: Instrument[];
      instrumentsMap?: { [id: number]: Instrument };
    } = {
      ...instrumentQuery,
      instruments: [],
      instrumentsMap: {},
    };
    if (instrumentEntitiesMap) {
      const instruments: Instrument[] = [];
      const instrumentsMap: { [id: number]: Instrument } = {};

      for (const key in instrumentEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(instrumentEntitiesMap, key)) {
          const c = instrumentEntitiesMap[key];
          if (c) {
            const instrument = new Instrument(c);
            instruments.push(instrument);
            if (instrument?.id) instrumentsMap[instrument.id] = instrument;
          }
        }
      }
      instruments.sort((a, b) => (a.name ?? "").localeCompare(b.name ?? ""));
      ret.instruments = instruments;
      ret.instrumentsMap = instrumentsMap;
    }

    return ret;
  }, [instrumentEntitiesMap]);
}
