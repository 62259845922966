import { accountKitApi, accountKitReducer } from "@nerdjs/account-kit";
import { networkMiddleware, networkReducer } from "@nerdjs/nerd-network";
import { notificationReducer } from "@nerdjs/nerd-ui";
import { combineReducers } from "redux";
import thunkMiddleware from "redux-thunk";
import { configureStore } from "@reduxjs/toolkit";
import { mainMiddleware } from "./mainMiddleware";
import { mercuryApi } from "./api/mercuryApi";
import { rhapsodySlice } from "../reducers/rhapsody";
import { museApi } from "./api/museApi";
import { autopilotApi } from "./api/autopilotApi";
import { rhapsodyApi } from "./api/rhapsodyApi";
import { unityApi } from "./api/unity";

const rootReducer = combineReducers({
  accountKitState: accountKitReducer,
  notification: notificationReducer,
  networkState: networkReducer,
  [rhapsodySlice.name]: rhapsodySlice.reducer,
  [mercuryApi.reducerPath]: mercuryApi.reducer,
  [museApi.reducerPath]: museApi.reducer,
  [unityApi.reducerPath]: unityApi.reducer,
  [rhapsodyApi.reducerPath]: rhapsodyApi.reducer,
  [autopilotApi.reducerPath]: autopilotApi.reducer,
  [accountKitApi.reducerPath]: accountKitApi.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export const store = configureStore({
  reducer: rootReducer,
  middleware: [
    thunkMiddleware,
    mainMiddleware,
    mercuryApi.middleware,
    museApi.middleware,
    rhapsodyApi.middleware,
    unityApi.middleware,
    autopilotApi.middleware,
    accountKitApi.middleware,
    networkMiddleware, // Always add networkMiddleware after your api middleware
  ],
});

export type AppDispatch = typeof store.dispatch;
