import NoteType_Entity from "./noteType";
export default class NoteType extends NoteType_Entity {
  static fromList(noteTypesJSON: unknown): Array<NoteType> {
    const noteTypes: NoteType[] = [];
    if (noteTypesJSON)
      Array.isArray(noteTypesJSON) &&
        noteTypesJSON.forEach((noteTypeJSON) => {
          noteTypes.push(new NoteType(noteTypeJSON));
        });
    return noteTypes;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
