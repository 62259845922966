import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { Section_Entity } from "../../entities/section";
import { museApi } from "../api/museApi";

const sectionsAdapter = createEntityAdapter<Section_Entity>();
const initialState = sectionsAdapter.getInitialState();

export const sectionEndpoints = museApi.injectEndpoints({
  endpoints: (build) => ({
    getSections: build.query<EntityState<Section_Entity>, void>({
      query: () => `sections`,
      transformResponse: (responseData: Section_Entity[]) => {
        return sectionsAdapter.setAll(initialState, responseData);
      },
      providesTags: ["sections"],
    }),
    getSection: build.query<Section_Entity, number>({
      query: (id) => `sections/${id}`,
      providesTags: (result, error, id) => [{ type: "sections", id }],
    }),
    createSection: build.mutation<Section_Entity, Partial<Section_Entity>>({
      query: (body) => ({
        method: "POST",
        body,
        url: `sections`,
      }),
      invalidatesTags: ["sections"],
    }),
    updateSection: build.mutation<
      void,
      { id: number; body: Partial<Section_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `sections/${args.id}`,
      }),
      invalidatesTags: ["sections"],
    }),
    deleteSection: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `sections/${id}`,
      }),
      invalidatesTags: ["sections"],
    }),
  }),
});

export const {
  useGetSectionQuery,
  useGetSectionsQuery,
  useCreateSectionMutation,
  useDeleteSectionMutation,
  useUpdateSectionMutation,
} = sectionEndpoints;
