import { Box } from "@mui/material";
import {
  DataGridPremium,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-premium";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setSelectedPipelineID } from "../../reducers/rhapsody";
import { usePipelines } from "../../redux/pipeline/pipelineHooks";
import { RouterConfig } from "../config/routerConfig";
import Id from "../id/id";

/**
 * Pipeline
 *
 * @returns {null} Pipeline
 */
export default function Pipelines() {
  const { pipelines } = usePipelines();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <DataGridPremium
      rows={pipelines ?? []}
      density="compact"
      columns={[
        {
          field: "id",
          headerName: "ID",
          type: "number",
          width: 60,
          renderCell: (p) => {
            if (p.id === -1) return <Box />;
            return (
              <Id
                startDecorator={
                  <i className="fa-regular fa-arrow-progress"></i>
                }
              >
                {p.id}
              </Id>
            );
          },
        },
        { field: "name", headerName: "Name", flex: 1 },
      ]}
      slots={{ toolbar: QuickSearchToolbar }}
      onRowClick={(e) => {
        navigate(`${RouterConfig.pipelines}/${e.id}`);
        dispatch(setSelectedPipelineID(e.row.id));
      }}
    />
  );
}

function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
        display: "flex",
      }}
    >
      <GridToolbarQuickFilter
        variant="outlined"
        size="small"
        sx={{ flex: 1 }}
        fullWidth
      />
    </Box>
  );
}
