import { useMemo } from "react";
import { EventStatus } from "../../entities/eventStatus";
import {
  useGetEventStatusQuery,
  useGetEventStatusesQuery,
} from "./eventStatusEndpoints";

export function useEventStatus(
  eventStatusID: number | undefined | null,
  opt?: { skip?: boolean }
) {
  const eventStatusQuery = useGetEventStatusQuery(eventStatusID ?? 0, {
    skip: !eventStatusID || opt?.skip,
  });
  const eventStatusEntity = eventStatusQuery.data;

  return useMemo(() => {
    const ret: typeof eventStatusQuery & {
      eventStatus?: EventStatus | undefined;
    } = {
      ...eventStatusQuery,
    };
    if (eventStatusEntity) ret.eventStatus = new EventStatus(eventStatusEntity);
    return ret;
  }, [eventStatusEntity]);
}

export function useEventStatuses() {
  const eventStatusQuery = useGetEventStatusesQuery();
  const eventStatusEntitiesMap = eventStatusQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof eventStatusQuery & {
      eventStatuses?: EventStatus[];
      eventStatusesMap?: { [id: number]: EventStatus };
    } = {
      ...eventStatusQuery,
      eventStatuses: [],
      eventStatusesMap: {},
    };
    if (eventStatusEntitiesMap) {
      const eventStatuses: EventStatus[] = [];
      const eventStatusesMap: { [id: number]: EventStatus } = {};

      for (const key in eventStatusEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(eventStatusEntitiesMap, key)) {
          const c = eventStatusEntitiesMap[key];
          if (c) {
            const eventStatus = new EventStatus(c);
            eventStatuses.push(eventStatus);
            if (eventStatus?.id) eventStatusesMap[eventStatus.id] = eventStatus;
          }
        }
      }
      ret.eventStatuses = eventStatuses;
      ret.eventStatusesMap = eventStatusesMap;
    }

    return ret;
  }, [eventStatusEntitiesMap]);
}
