import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { mercuryApi } from "../api/mercuryApi";
import { Interactor_Entity } from "../../entities/interactor";

const interactorsAdapter = createEntityAdapter<Interactor_Entity>();
const initialState = interactorsAdapter.getInitialState();

export const interactorEndpoints = mercuryApi.injectEndpoints({
  endpoints: (build) => ({
    getInteractors: build.query<EntityState<Interactor_Entity>, void>({
      query: () => `interactors`,
      transformResponse: (responseData: Interactor_Entity[]) => {
        return interactorsAdapter.setAll(initialState, responseData);
      },
      providesTags: ["interactors"],
    }),
    getInteractor: build.query<Interactor_Entity, number>({
      query: (id) => `interactors/${id}`,
      providesTags: (result, error, id) => [{ type: "interactors", id }],
    }),
    createInteractor: build.mutation<
      Interactor_Entity,
      Partial<Interactor_Entity>
    >({
      query: (body) => ({
        method: "POST",
        body,
        url: `interactors`,
      }),
      invalidatesTags: ["interactors", "pipelines"],
    }),
    updateInteractor: build.mutation<
      void,
      { id: number; body: Partial<Interactor_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `interactors/${args.id}`,
      }),
      invalidatesTags: ["interactors", "pipelines"],
    }),
    deleteInteractor: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `interactors/${id}`,
      }),
      invalidatesTags: ["interactors", "pipelines"],
    }),
  }),
});

export const {
  useGetInteractorQuery,
  useGetInteractorsQuery,
  useCreateInteractorMutation,
  useDeleteInteractorMutation,
  useUpdateInteractorMutation,
} = interactorEndpoints;
