import { useMemo } from "react";
import { Event } from "../../entities/event";
import { useGetEventQuery, useGetEventsQuery } from "./eventEndpoints";

export function useEvent(
  eventID: number | undefined | null,
  opt?: { skip?: boolean }
) {
  const eventQuery = useGetEventQuery(eventID ?? 0, {
    skip: !eventID || opt?.skip,
  });
  const eventEntity = eventQuery.data;

  return useMemo(() => {
    const ret: typeof eventQuery & {
      event?: Event | undefined;
    } = {
      ...eventQuery,
    };
    if (eventEntity) ret.event = new Event(eventEntity);
    return ret;
  }, [eventEntity]);
}

export function useEvents() {
  const eventQuery = useGetEventsQuery();
  const eventEntitiesMap = eventQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof eventQuery & {
      events?: Event[];
      eventsMap?: { [id: number]: Event };
    } = {
      ...eventQuery,
      events: [],
      eventsMap: {},
    };
    if (eventEntitiesMap) {
      const events: Event[] = [];
      const eventsMap: { [id: number]: Event } = {};

      for (const key in eventEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(eventEntitiesMap, key)) {
          const c = eventEntitiesMap[key];
          if (c) {
            const event = new Event(c);
            events.push(event);
            if (event?.id) eventsMap[event.id] = event;
          }
        }
      }
      ret.events = events;
      ret.eventsMap = eventsMap;
    }

    return ret;
  }, [eventEntitiesMap]);
}
