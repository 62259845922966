import {
  Button,
  Checkbox,
  Chip,
  ChipDelete,
  Divider,
  Input,
  List,
  ListDivider,
  ListItem,
  ListItemDecorator,
  Option,
  Select,
  Typography,
  listItemDecoratorClasses,
  optionClasses,
} from "@mui/joy";
import { Box, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Instrument_Entity } from "../../entities/instrument";
import {
  formOpenSelector,
  selectedInstrumentIDSelector,
  setFormOpen,
  setSelectedInstrumentID,
} from "../../reducers/rhapsody";
import {
  useCreateInstrumentMutation,
  useUpdateInstrumentMutation,
} from "../../redux/instrument/instrumentEndpoints";
import { useInstrument } from "../../redux/instrument/instrumentHooks";
import DialogClose from "../dialogClose/dialogClose";
import { useSectionInstruments } from "../../redux/sectionInstrument/sectionInstrumentHooks";
import { useSections } from "../../redux/section/sectionHooks";
import { SectionInstrument } from "../../entities/sectionInstrument";
import {
  useCreateSectionInstrumentMutation,
  useDeleteSectionInstrumentMutation,
} from "../../redux/sectionInstrument/sectionInstrumentEndpoints";
import { Check } from "@mui/icons-material";
import { useFamilies } from "../../redux/family/familyHooks";

/**
 *
 * @returns {ReactElement} InstrumentForm page
 */
export function InstrumentForm() {
  const open = useSelector(formOpenSelector("instrument"));
  const dispatch = useDispatch();
  const selectedInstrumentID = useSelector(selectedInstrumentIDSelector);
  const { instrument } = useInstrument(selectedInstrumentID);
  const [createInstrument, { isLoading: l1 }] = useCreateInstrumentMutation();
  const [updateInstrument, { isLoading: l2 }] = useUpdateInstrumentMutation();
  const [deleteSectionInstrument] = useDeleteSectionInstrumentMutation();
  const [createSectionInstrument] = useCreateSectionInstrumentMutation();
  const { sectionInstruments: allSectionInstruments } = useSectionInstruments();
  const { sectionsMap, sections } = useSections();
  const { families } = useFamilies();
  const [tmpValues, setTmpValues] = useState<number[]>([]);
  const [_instrument, setInstrument] = useState<Partial<Instrument_Entity>>({
    name: "",
  });

  useEffect(() => {
    if (instrument?.id) setInstrument(instrument);
  }, [instrument]);

  const close = () => {
    dispatch(setFormOpen({ isOpen: false, formID: "instrument" }));
    dispatch(setSelectedInstrumentID(null));
  };

  const save = () => {
    if (_instrument.id) {
      updateInstrument({ id: _instrument.id, body: _instrument });
    } else {
      createInstrument(_instrument);
    }
    close();
  };

  const sectionInstruments: SectionInstrument[] =
    allSectionInstruments?.filter(
      (si) => si.instrumentID === selectedInstrumentID
    ) ?? [];

  const saveSectionInstruments = (sectionIDs: number[]) => {
    // delete
    sectionInstruments.forEach((si) => {
      if (si?.sectionID && !sectionIDs.includes(si?.sectionID)) {
        deleteSectionInstrument(si.id);
      }
    });

    // create
    sectionIDs.forEach((sectionID) => {
      if (
        selectedInstrumentID &&
        sectionInstruments.findIndex((si) => si.sectionID === sectionID) === -1
      ) {
        createSectionInstrument({
          sectionID,
          instrumentID: selectedInstrumentID,
        });
      }
    });
  };

  useEffect(() => {
    if (sectionInstruments)
      setTmpValues(
        sectionInstruments.reduce<number[]>((a, v) => {
          if (v.sectionID) a.push(v.sectionID);
          return a;
        }, [])
      );
  }, [allSectionInstruments]);

  return (
    <DialogClose open={open} onClose={close} fullWidth maxWidth="xs">
      <DialogTitle>
        <Typography
          level="title-lg"
          startDecorator={<i className="fa-solid fa-trumpet"></i>}
        >
          {_instrument?.name ? _instrument?.name : "New Instrument"}
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ gap: 1, flexDirection: "column", display: "flex" }}>
        <Input
          value={_instrument.name}
          onChange={(e) =>
            setInstrument((p) => ({ ...p, name: e.target.value }))
          }
          autoFocus
          startDecorator="Name:"
          sx={{ boxShadow: "none" }}
        />
        <Input
          value={_instrument.shorthand}
          onChange={(e) =>
            setInstrument((p) => ({ ...p, shorthand: e.target.value }))
          }
          startDecorator="Shorthand:"
          sx={{ boxShadow: "none" }}
        />
        <Select
          startDecorator="Family:"
          sx={{ boxShadow: "none" }}
          value={_instrument.familyID}
          onChange={(e, v) =>
            setInstrument((p) => ({ ...p, familyID: v ?? 0 }))
          }
          slotProps={{
            listbox: {
              component: "div",
              sx: {
                zIndex: 9999,
                overflow: "auto",
                "--List-padding": "0px",
                "--ListItem-radius": "0px",
              },
            },
          }}
        >
          {families?.map((f) => (
            <Option value={f.id} key={f.id}>
              {f.name}
            </Option>
          ))}
        </Select>
        <Checkbox
          checked={_instrument.cartage ?? false}
          onChange={(e) => {
            setInstrument((p) => ({ ...p, cartage: e.target.checked }));
          }}
          label="Cartage"
        />
        <Divider>Sections</Divider>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <Select
            multiple
            placeholder="Add Section"
            value={tmpValues}
            onChange={(e, v) => {
              setTmpValues(v);
            }}
            onClose={() => {
              saveSectionInstruments(tmpValues);
              setTmpValues([]);
            }}
            slotProps={{
              listbox: {
                component: "div",
                sx: {
                  zIndex: 9999,
                  overflow: "auto",
                  "--List-padding": "0px",
                  "--ListItem-radius": "0px",
                },
              },
            }}
            sx={{ boxShadow: "none" }}
          >
            {families?.map((f, index) => {
              const _sections =
                sections?.filter((s) => s.familyID === f.id) ?? [];
              return (
                <React.Fragment key={f.id}>
                  {index !== 0 && <ListDivider role="none" />}
                  <List
                    aria-labelledby={`select-group-${name}`}
                    sx={{ "--ListItemDecorator-size": "28px" }}
                  >
                    <ListItem id={`select-group-${name}`} sticky>
                      <Typography level="body-xs" textTransform="uppercase">
                        {f.name} ({_sections.length})
                      </Typography>
                    </ListItem>
                    {_sections.map((s) => (
                      <Option
                        key={s.id}
                        value={s.id}
                        label={s.name}
                        sx={{
                          [`&.${optionClasses.selected} .${listItemDecoratorClasses.root}`]:
                            {
                              opacity: 1,
                            },
                        }}
                      >
                        {s.name}
                        {sectionInstruments.find(
                          (s) => s.sectionID === s.id
                        ) ? (
                          <ListItemDecorator>
                            <Check color="primary" />
                          </ListItemDecorator>
                        ) : (
                          []
                        )}
                      </Option>
                    ))}
                  </List>
                </React.Fragment>
              );
            })}
          </Select>
          <Box sx={{ display: "flex", gap: 1 }}>
            {sectionInstruments?.map((si) => {
              const section =
                sectionsMap && si.sectionID
                  ? sectionsMap[si.sectionID]
                  : undefined;

              return (
                <Chip
                  key={si.id}
                  endDecorator={
                    <ChipDelete
                      onDelete={() => deleteSectionInstrument(si.id)}
                    />
                  }
                >
                  {section?.name}
                </Chip>
              );
            })}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="soft" color="neutral" onClick={close}>
          Close
        </Button>
        <Button loading={l1 || l2} onClick={save} disabled={!_instrument.name}>
          {selectedInstrumentID ? "Save" : "Create"}
        </Button>
      </DialogActions>
    </DialogClose>
  );
}
