import Section_Entity from "./section";
export default class Section extends Section_Entity {
  static fromList(sectionsJSON: unknown): Array<Section> {
    const sections: Section[] = [];
    if (sectionsJSON)
      Array.isArray(sectionsJSON) &&
        sectionsJSON.forEach((sectionJSON) => {
          sections.push(new Section(sectionJSON));
        });
    return sections;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
