import { Button, Grid } from "@mui/material";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AppConfig } from "../environement";
import { RouterConfig } from "../hooks/config/routerConfig";

/**
 *
 * @returns {ReactElement} NotFound route
 */
export default function NotFoundRoute(): ReactElement {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Grid gap={10} container justifyContent={"center"} mt={10}>
      <Grid item xs={12} display={"flex"} justifyContent={"center"}>
        <img src={AppConfig.app.notFoundImage} width={"45%"} />
      </Grid>
      <Grid item marginTop={"auto"}>
        <Button
          variant="contained"
          sx={{
            backgroundImage: `url(${AppConfig.app.notFoundImage})`,
            backgroundPosition: "center",
            backgroundSize: "480px",
          }}
          onClick={() => {
            navigate(RouterConfig.root);
          }}
        >
          {t("global.back_to_home")}
        </Button>
      </Grid>
    </Grid>
  );
}
