import Event_Entity from "./event";
export default class Event extends Event_Entity {
  static fromList(eventsJSON: unknown): Array<Event> {
    const events: Event[] = [];
    if (eventsJSON)
      Array.isArray(eventsJSON) &&
        eventsJSON.forEach((eventJSON) => {
          events.push(new Event(eventJSON));
        });
    return events;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
