import { useMemo } from "react";
import { Configuration } from "../../entities/configuration";
import {
  useGetConfigurationQuery,
  useGetConfigurationsQuery,
} from "./configurationEndpoints";

export function useConfiguration(
  configurationID: number | undefined | null,
  opt?: { skip?: boolean }
) {
  const configurationQuery = useGetConfigurationQuery(configurationID ?? 0, {
    skip: !configurationID || opt?.skip,
  });
  const configurationEntity = configurationQuery.data;

  return useMemo(() => {
    const ret: typeof configurationQuery & {
      configuration?: Configuration | undefined;
    } = {
      ...configurationQuery,
    };
    if (configurationEntity)
      ret.configuration = new Configuration(configurationEntity);
    return ret;
  }, [configurationEntity]);
}

export function useConfigurations() {
  const configurationQuery = useGetConfigurationsQuery();
  const configurationEntitiesMap = configurationQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof configurationQuery & {
      configurations?: Configuration[];
      configurationsMap?: { [id: number]: Configuration };
    } = {
      ...configurationQuery,
      configurations: [],
      configurationsMap: {},
    };
    if (configurationEntitiesMap) {
      const configurations: Configuration[] = [];
      const configurationsMap: { [id: number]: Configuration } = {};

      for (const key in configurationEntitiesMap) {
        if (
          Object.prototype.hasOwnProperty.call(configurationEntitiesMap, key)
        ) {
          const c = configurationEntitiesMap[key];
          if (c) {
            const configuration = new Configuration(c);
            configurations.push(configuration);
            if (configuration?.id)
              configurationsMap[configuration.id] = configuration;
          }
        }
      }
      ret.configurations = configurations;
      ret.configurationsMap = configurationsMap;
    }

    return ret;
  }, [configurationEntitiesMap]);
}
