import {
  CircularProgress,
  Typography,
  useColorScheme,
  useTheme,
} from "@mui/joy";
import { AppBar, Box, CssBaseline, Grid, useMediaQuery } from "@mui/material";
import {
  useGetCurrentUserQuery,
  useSignOutMutation,
} from "@nerdjs/account-kit";
import { initNetworkAction } from "@nerdjs/nerd-network";
import { NerdNotifier, clearNotification } from "@nerdjs/nerd-ui";
import { PropsWithChildren, ReactElement, useEffect } from "react";
import { AppRouter } from "../../appRouter";
import { AppConfig } from "../../environement";
import { RouterConfig } from "../config/routerConfig";
import { useAppDispatch, useAppSelector } from "../hooks";
import { MainMenu } from "../mainMenu/mainMenu";
import NerdAppsMenuWrapper from "../nerdAppsMenuWrapper/nerdAppsMenuWrapper";
import { NerdImpersonate } from "../nerdImpersonate/nerdImpersonate";
import { NerdUserMenu } from "../nerdUserMenu/nerdUserMenu";
import { CreateCenter } from "../createCenter/createCenter";

/**
 * App wrapper
 *
 * @returns {ReactElement} App wrapper
 */
export default function NerdApp(): ReactElement {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"), {
    noSsr: true,
  });
  const { isSuccess } = useSignOutMutation()[1];

  useEffect(() => {
    dispatch(initNetworkAction(AppConfig.api));
  }, []);

  const { isFetching, isError } = useGetCurrentUserQuery();

  useEffect(() => {
    //If signout success, refresh window to reset redux state
    if (isSuccess) {
      window.location.replace(RouterConfig.root);
    }
  }, [isSuccess, isError]);

  return (
    <NotifierWrapper>
      <Box
        sx={{
          backgroundImage: `url("${AppConfig.app.rootBackground}") `,
          backgroundSize: "cover",
          display: "flex",
          flex: 1,
          flexDirection: md ? "column" : "row",
        }}
      >
        <CssBaseline />
        {isFetching ? (
          <Grid justifyContent={"center"} alignItems={"center"} container>
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        ) : (
          <>
            <AppBar
              variant="outlined"
              sx={{
                height: 48,
                background: "#081D3A",
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: 1,
                flexDirection: "row",
                pl: 1,
                pr: 1,
                justifyContent: "space-between",
                overflow: "hidden",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "end", gap: 1 }}>
                <img
                  src="https://storage.googleapis.com/wid-rhapsody/images/logo_main-white.png"
                  height="32"
                />
                <Typography
                  level="title-lg"
                  sx={{
                    fontFamily: "neonoir",
                    fontWeight: 700,
                    color: "white",
                    fontSize: 26,
                    animation: `pulsate 0.11s ease-in-out infinite alternate`,
                    textShadow: `0 0 7px #fff,
    0 0 10px #fff,
    0 0 21px #fff,
    0 0 42px #2196f3,
    0 0 82px #2196f3,
    0 0 92px #2196f3,
    0 0 102px #2196f3,
    0 0 151px #2196f3;`,
                  }}
                >
                  Conductor
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  alignItems: "center",
                }}
              >
                <NerdAppsMenuWrapper key={"NerdAppsMenuWrapper"} />
                <NerdUserMenu key={"myAccountButton"} />
                <NerdImpersonate key={"NerdImpersonate"} />
              </Box>
            </AppBar>
            <MainMenu />
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                display: "flex",
              }}
            >
              <AppRouter />
              <CreateCenter />
            </Box>
          </>
        )}
      </Box>
    </NotifierWrapper>
  );
}

const NotifierWrapper = ({ children }: { children: ReactElement }) => {
  const dispatch = useAppDispatch();
  const notification = useAppSelector((s) => s.notification);
  return (
    notification && (
      <NerdNotifier
        {...notification}
        onClose={() => {
          dispatch(clearNotification());
          notification.onClose?.();
        }}
      >
        {children}
      </NerdNotifier>
    )
  );
};
