import {
  createEntityAdapter,
  EntityState,
  createSelector,
  configureStore,
} from "@reduxjs/toolkit";
import { mercuryApi } from "../api/mercuryApi";
import { Pipeline_Entity } from "../../entities/pipeline";

const pipelinesAdapter = createEntityAdapter<Pipeline_Entity>();
const initialState = pipelinesAdapter.getInitialState();

export const pipelineEndpoints = mercuryApi.injectEndpoints({
  endpoints: (build) => ({
    getPipelines: build.query<EntityState<Pipeline_Entity>, void>({
      query: () => `pipelines`,
      transformResponse: (responseData: Pipeline_Entity[]) => {
        return pipelinesAdapter.setAll(initialState, responseData);
      },
      providesTags: ["pipelines"],
    }),
    getPipeline: build.query<Pipeline_Entity, number>({
      query: (id) => `pipelines/${id}`,
      providesTags: (result, error, id) => [{ type: "pipelines", id }],
    }),
    duplicatePipeline: build.query<Pipeline_Entity, number>({
      query: (id) => `pipelines/${id}/duplicate`,
      providesTags: (result, error, id) => [{ type: "pipelines", id }],
    }),
    createPipeline: build.mutation<Pipeline_Entity, Partial<Pipeline_Entity>>({
      query: (body) => ({
        method: "POST",
        body,
        url: `pipelines`,
      }),
      invalidatesTags: ["pipelines"],
    }),
    updatePipeline: build.mutation<
      void,
      { id: number; body: Partial<Pipeline_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `pipelines/${args.id}`,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "pipelines", id },
        "pipelines",
      ],
    }),
    deletePipeline: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `pipelines/${id}`,
      }),
      invalidatesTags: ["pipelines"],
    }),
  }),
});

export const {
  useLazyDuplicatePipelineQuery,
  useGetPipelineQuery,
  useGetPipelinesQuery,
  useCreatePipelineMutation,
  useDeletePipelineMutation,
  useUpdatePipelineMutation,
} = pipelineEndpoints;

export default pipelineEndpoints;

export const selectPipelinesResult =
  pipelineEndpoints.endpoints.getPipelines.select();

const selectPipelinesData = createSelector(
  selectPipelinesResult,
  (pipelinesResult) => pipelinesResult.data
);

const store = configureStore({
  reducer: {
    [mercuryApi.reducerPath]: mercuryApi.reducer,
  },
});

type RootState = ReturnType<typeof store.getState>;

export const { selectAll: selectAllPipelines, selectById: selectPipelineById } =
  pipelinesAdapter.getSelectors<RootState>(
    (state) => selectPipelinesData(state) ?? initialState
  );
