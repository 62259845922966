import {
  Button,
  Checkbox,
  Chip,
  Divider,
  Dropdown,
  IconButton,
  Input,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemDecorator,
  Menu,
  MenuButton,
  MenuItem,
  Option,
  Select,
  Sheet,
  Textarea,
  Tooltip,
  Typography,
} from "@mui/joy";
import { Box, ClickAwayListener, Collapse } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReactFlow, {
  Background,
  Controls,
  Edge,
  Handle,
  MiniMap,
  Node,
  OnConnect,
  OnEdgesChange,
  OnNodesChange,
  Position,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  useReactFlow,
} from "reactflow";
import "reactflow/dist/style.css";
import { EventStatus_Entity } from "../entities/eventStatus";
import { Interactor, Interactor_Entity } from "../entities/interactor";
import { Pipeline, Pipeline_Entity } from "../entities/pipeline";
import { Stage, Stage_Entity } from "../entities/stage";
import ColorPicker from "../hooks/colorPicker/colorPicker";
import { RouterConfig } from "../hooks/config/routerConfig";
import Upload from "../hooks/fileUpload/fileUpload";
import Id from "../hooks/id/id";
import {
  selectedPipelineIDSelector,
  selectedStageIDSelector,
  setFormOpen,
  setSelectedPipelineID,
  setSelectedStageID,
} from "../reducers/rhapsody";
import {
  useCreateEventStatusMutation,
  useDeleteEventStatusMutation,
  useUpdateEventStatusMutation,
} from "../redux/eventStatus/eventStatusEndpoints";
import { useEventStatuses } from "../redux/eventStatus/eventStatusHooks";
import {
  useCreateInteractorMutation,
  useDeleteInteractorMutation,
  useUpdateInteractorMutation,
} from "../redux/interactor/interactorEndpoints";
import { useInteractors } from "../redux/interactor/interactorHooks";
import {
  useDeletePipelineMutation,
  useLazyDuplicatePipelineQuery,
  useUpdatePipelineMutation,
} from "../redux/pipeline/pipelineEndpoints";
import { usePipeline, usePipelines } from "../redux/pipeline/pipelineHooks";
import {
  useCreateStageMutation,
  useDeleteStageMutation,
  useUpdateStageMutation,
} from "../redux/stage/stageEndpoints";
import { useStages } from "../redux/stage/stageHooks";

/**
 *
 * @returns {ReactElement} A happy coding page!
 */
export function PipelinePage() {
  return (
    <Box
      sx={{
        flex: 1,
        height: "100%",
        background: "#F6F8FA",
        display: "flex",
      }}
    >
      <Flow />
      <StageDrawer />
    </Box>
  );
}

function Flow() {
  const pipelineID = useSelector(selectedPipelineIDSelector);
  const { pipeline } = usePipeline(pipelineID);
  const [nodes, setNodes] = useState<Node[]>([]);
  const [edges, setEdges] = useState<Edge[]>([]);
  const nodeTypes = useMemo(() => ({ stage: StageNode }), []);
  const dispatch = useDispatch();
  const [hover, setHover] = useState<string | null>();
  const [updateStage] = useUpdateStageMutation();

  useEffect(() => {
    if (pipeline) {
      setNodes(pipeline.getNodes());
      setEdges(pipeline.getEdges());
    }
  }, [pipeline]);

  const onNodesChange: OnNodesChange = useCallback(
    (changes) => {
      setNodes((nds) => applyNodeChanges(changes, nds));
    },
    [setNodes]
  );
  const onEdgesChange: OnEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges]
  );
  const onConnect: OnConnect = useCallback(
    (connection) => setEdges((eds) => addEdge(connection, eds)),
    [setEdges]
  );

  const _edges = hover
    ? edges.reduce<Edge[]>((a, v) => {
        if (v.source === hover || v.target === hover) {
          a.push({
            ...v,
            style: {
              stroke: v.source === hover ? "#3774CB" : "#e91e63",
              strokeWidth: 2,
              transition: "stroke-width .2s, stroke .2s",
            },
          });
        } else {
          a.push(v);
        }
        return a;
      }, [])
    : edges;

  return (
    <ReactFlow
      onClick={() => dispatch(setSelectedStageID(null))}
      onNodeMouseEnter={(e, node) => setHover(node.id)}
      onNodeDragStop={(e, v) => {
        updateStage({
          id: v.data.stage.id,
          body: {
            ...v.data.stage,
            position: `${v.position.x},${v.position.y}`,
          },
        });
      }}
      onNodeMouseLeave={() => setHover(null)}
      onNodeClick={(e, node) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(setSelectedStageID(node.data.stage.id));
        dispatch(setFormOpen({ isOpen: true, formID: "stage" }));
      }}
      fitView
      nodes={nodes}
      edges={_edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onConnect={onConnect}
      nodeTypes={nodeTypes}
    >
      <Toolbar />
      <Background />
      <Controls />
      <MiniMap nodeColor={(n) => n.data.stage.color} />
    </ReactFlow>
  );
}

function Toolbar() {
  const pipelineID = useSelector(selectedPipelineIDSelector);
  const { pipeline } = usePipeline(pipelineID);
  const { pipelines } = usePipelines();
  const [createStage] = useCreateStageMutation();
  const [updatePipeline] = useUpdatePipelineMutation();
  const [_pipeline, setPipeline] = useState<Partial<Pipeline_Entity>>({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setCenter, getZoom } = useReactFlow();
  const [deletePipeline] = useDeletePipelineMutation();
  const [duplicatePipeline] = useLazyDuplicatePipelineQuery();
  const [inheritOpen, setInheritOpen] = useState(false);
  const [exStageOpen, setExStageOpen] = useState(false);
  const [exInteractorOpen, setExInteractorOpen] = useState(false);
  const { stagesMap: allStagesMap } = useStages();
  const { interactorsMap: allInteractorsMap } = useInteractors();

  useEffect(() => {
    if (pipeline) {
      setPipeline(pipeline);
    }
  }, [pipeline]);

  const save = () => {
    if (pipeline && _pipeline) {
      updatePipeline({ id: pipeline?.id, body: _pipeline });
    }
  };

  if (!_pipeline) return <Box />;

  const inherit = JSON.parse(pipeline?.inherit ? pipeline?.inherit : "[]");

  const excludedStageIDs: number[] = JSON.parse(
    pipeline?.excludedStageIDs ? pipeline?.excludedStageIDs : "[]"
  );

  const excludedInteractorIDs: number[] = JSON.parse(
    pipeline?.excludedInteractorIDs ? pipeline?.excludedInteractorIDs : "[]"
  );

  const stages = [...(pipeline?.stages ?? [])];
  excludedStageIDs.forEach((sID) => {
    if (allStagesMap && allStagesMap[sID]) stages?.unshift(allStagesMap[sID]);
  });

  const pipelineInteractors =
    pipeline?.stages.reduce<Interactor[]>((a, v) => {
      a.push(...(v.interactors ?? []));
      return a;
    }, []) ?? [];
  const interactors = [...pipelineInteractors];
  excludedInteractorIDs.forEach((sID) => {
    if (allInteractorsMap && allInteractorsMap[sID])
      interactors?.unshift(allInteractorsMap[sID]);
  });

  return (
    <Box
      onClick={(e) => {
        e.stopPropagation();
        // e.preventDefault();
      }}
      sx={{
        position: "absolute",
        top: 0,
        width: "100%",
        background: "rgba(255,255,255,0.8)",
        p: 1,
        zIndex: 999,
        backdropFilter: "blur(10px)",
        borderBottom: "solid 1px #E1E1E1",
        transition: "width .2s",
        display: "flex",
        gap: 1,
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <Button
          variant="plain"
          size="sm"
          startDecorator={<i className="fa-solid fa-chevron-left"></i>}
          onClick={() => {
            navigate(RouterConfig.pipelines);
            dispatch(setSelectedPipelineID(null));
            dispatch(setSelectedStageID(null));
          }}
        >
          Back
        </Button>
        <Input
          startDecorator="Name:"
          size="sm"
          sx={{ boxShadow: "none" }}
          value={_pipeline?.name}
          endDecorator={<Id>{_pipeline.id}</Id>}
          onChange={(e) => setPipeline((p) => ({ ...p, name: e.target.value }))}
          onBlur={save}
        />
        <Input
          startDecorator="OrgID:"
          size="sm"
          sx={{ boxShadow: "none", width: 160 }}
          value={_pipeline?.organizationID}
          type="number"
          onChange={(e) =>
            setPipeline((p) => ({
              ...p,
              organizationID: parseInt(e.target.value),
            }))
          }
          onBlur={save}
        />
        <Tooltip
          arrow
          variant="outlined"
          open={inheritOpen}
          title={
            <ClickAwayListener onClickAway={() => setInheritOpen(false)}>
              <List sx={{ maxHeight: 800, overflow: "scroll" }}>
                {pipelines?.map((p) => {
                  const selected = inherit?.includes(p.id);
                  let _inherit = [...inherit];
                  if (selected) {
                    _inherit = _inherit.filter((e) => e !== p.id);
                  } else {
                    _inherit.push(p.id);
                  }

                  return (
                    <ListItemButton
                      onClick={(e) => {
                        updatePipeline({
                          id: _pipeline.id!,
                          body: {
                            ..._pipeline,
                            inherit: JSON.stringify(_inherit),
                          },
                        });
                      }}
                      color={selected ? "primary" : undefined}
                      selected={selected}
                      key={p.id}
                    >
                      {p.name}
                    </ListItemButton>
                  );
                })}
              </List>
            </ClickAwayListener>
          }
        >
          <Button
            variant="outlined"
            color="neutral"
            onClick={() => setInheritOpen(true)}
            endDecorator={
              <Typography level="body-xs" variant="soft">
                {inherit.length}
              </Typography>
            }
            size="sm"
          >
            Inherit
          </Button>
        </Tooltip>
        <Tooltip
          arrow
          variant="outlined"
          open={exStageOpen}
          title={
            <ClickAwayListener onClickAway={() => setExStageOpen(false)}>
              <List sx={{ maxHeight: 800, overflow: "scroll" }}>
                {stages?.map((p) => {
                  const selected = excludedStageIDs?.includes(p.id);
                  let _excludedStageIDs = [...excludedStageIDs];
                  if (selected) {
                    _excludedStageIDs = _excludedStageIDs.filter(
                      (e) => e !== p.id
                    );
                  } else {
                    _excludedStageIDs.push(p.id);
                  }

                  return (
                    <ListItemButton
                      onClick={(e) => {
                        updatePipeline({
                          id: _pipeline.id!,
                          body: {
                            ..._pipeline,
                            excludedStageIDs: JSON.stringify(_excludedStageIDs),
                          },
                        });
                      }}
                      color={selected ? "primary" : undefined}
                      selected={selected}
                      key={p.id}
                    >
                      {p.name}
                    </ListItemButton>
                  );
                })}
              </List>
            </ClickAwayListener>
          }
        >
          <Button
            variant="outlined"
            color="neutral"
            onClick={() => setExStageOpen(true)}
            endDecorator={
              <Typography level="body-xs" variant="soft">
                {excludedStageIDs.length}
              </Typography>
            }
            size="sm"
          >
            Exclude Stage
          </Button>
        </Tooltip>
        <Tooltip
          arrow
          variant="outlined"
          open={exInteractorOpen}
          title={
            <ClickAwayListener onClickAway={() => setExInteractorOpen(false)}>
              <List sx={{ maxHeight: 800, overflow: "scroll" }}>
                {interactors?.map((p) => {
                  const selected = excludedInteractorIDs?.includes(p.id);
                  let _excludedInteractorIDs = [...excludedInteractorIDs];
                  if (selected) {
                    _excludedInteractorIDs = _excludedInteractorIDs.filter(
                      (e) => e !== p.id
                    );
                  } else {
                    _excludedInteractorIDs.push(p.id);
                  }

                  return (
                    <ListItemButton
                      onClick={(e) => {
                        updatePipeline({
                          id: _pipeline.id!,
                          body: {
                            ..._pipeline,
                            excludedInteractorIDs: JSON.stringify(
                              _excludedInteractorIDs
                            ),
                          },
                        });
                      }}
                      color={selected ? "primary" : undefined}
                      selected={selected}
                      key={p.id}
                    >
                      {allStagesMap && allStagesMap[p?.stageID ?? 0]?.name}
                      <i className="fa-solid fa-arrow-right"></i>
                      {p.name}
                    </ListItemButton>
                  );
                })}
              </List>
            </ClickAwayListener>
          }
        >
          <Button
            variant="outlined"
            color="neutral"
            onClick={() => setExInteractorOpen(true)}
            endDecorator={
              <Typography level="body-xs" variant="soft">
                {excludedInteractorIDs.length}
              </Typography>
            }
            size="sm"
          >
            Exclude Interactors
          </Button>
        </Tooltip>
        <Checkbox
          size="sm"
          checked={_pipeline?.public ?? false}
          label="Public"
          onChange={(e) => {
            setPipeline((p) => ({ ...p, public: e.target.checked }));
            updatePipeline({
              id: _pipeline.id!,
              body: { ..._pipeline, public: e.target.checked },
            });
          }}
        />
      </Box>
      <Box sx={{ display: "flex", gap: 1 }}>
        <Button
          endDecorator={<i className="fa-solid fa-play"></i>}
          variant="soft"
          color="neutral"
          size="sm"
          onClick={() =>
            dispatch(setFormOpen({ isOpen: true, formID: "testPipeline" }))
          }
        >
          Test Pipeline
        </Button>
        <Dropdown>
          <MenuButton
            slots={{ root: IconButton }}
            slotProps={{ root: { variant: "outlined", color: "neutral" } }}
          >
            <i className="fa-solid fa-ellipsis-vertical"></i>
          </MenuButton>
          <Menu>
            <MenuItem
              variant="soft"
              onClick={() => {
                if (_pipeline?.id) {
                  duplicatePipeline(_pipeline.id)
                    .unwrap()
                    .then((e) => {
                      navigate(`${RouterConfig.pipelines}/${e.id}`);
                    });
                }
              }}
            >
              <ListItemDecorator sx={{ color: "inherit" }}>
                <i className="fa-solid fa-copy"></i>
              </ListItemDecorator>
              Duplicate
            </MenuItem>
            <MenuItem
              variant="soft"
              color="danger"
              onClick={() => {
                if (_pipeline?.id) {
                  deletePipeline(_pipeline.id);
                  navigate(RouterConfig.pipelines);
                }
              }}
            >
              <ListItemDecorator sx={{ color: "inherit" }}>
                <i className="fa-solid fa-trash"></i>
              </ListItemDecorator>
              Delete
            </MenuItem>
          </Menu>
        </Dropdown>
        <Tooltip arrow title="New Stage" variant="outlined" size="sm">
          <IconButton
            onClick={() => {
              createStage({
                pipelineID: pipeline?.id,
                terminus: false,
                name: "New Stage",
                color: "#000000",
                position: "0,0",
              })
                .unwrap()
                .then((e) => {
                  setCenter(0, 0, { zoom: getZoom(), duration: 1000 });
                  setTimeout(
                    () => dispatch(setSelectedStageID(e.id ?? 0)),
                    500
                  );
                });
            }}
            variant="soft"
          >
            <i className="fa-solid fa-plus"></i>
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
}

export function StageDrawer({ stageID: _stageID }: { stageID?: number }) {
  const selectedStageID = useSelector(selectedStageIDSelector);
  const stageID = _stageID ?? selectedStageID;
  const pipelineID = useSelector(selectedPipelineIDSelector);
  const { pipeline } = usePipeline(pipelineID);
  const { pipelines } = usePipelines();
  const [_stage, setStage] = useState<Partial<Stage_Entity>>({});
  const [updateStage] = useUpdateStageMutation();
  const [deleteStage] = useDeleteStageMutation();
  const [createInteractor] = useCreateInteractorMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    const stage = pipeline?.stages.find((s) => s.id === stageID);
    if (stage) {
      setStage(stage);
    }
  }, [stageID, pipeline]);

  const save = () => {
    if (stageID && _stage) updateStage({ id: stageID, body: _stage });
  };

  if (!pipeline || !_stage) return <Box />;

  const available = _stage.available?.split(",").filter((e) => e) ?? [];
  const excluded = _stage.excluded?.split(",").filter((e) => e) ?? [];

  return (
    <Box
      sx={{
        background: "white",
        display: "flex",
        width: stageID ? 320 : 0,
        transition: "width .2s",
        borderLeft: stageID && !_stageID ? "solid 1px #E1E1E1" : undefined,
        p: stageID ? 2 : 0,
        pt: 1,
        overflowY: "scroll",
        overflowX: "hidden",
        height: !_stageID ? `calc(100vh - 96px)` : undefined,
        position: "relative",
      }}
    >
      {stageID && _stage ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: "100%",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              startDecorator={<img height="18px" src={_stage.icon} />}
              level="title-lg"
              endDecorator={<Id>{_stage.id}</Id>}
            >
              {_stage.name}
            </Typography>
            <Dropdown>
              <MenuButton
                slots={{ root: IconButton }}
                slotProps={{ root: { variant: "outlined", color: "neutral" } }}
              >
                <i className="fa-solid fa-ellipsis-vertical"></i>
              </MenuButton>
              <Menu>
                <MenuItem
                  variant="soft"
                  color="danger"
                  onClick={() => {
                    deleteStage(stageID);
                    dispatch(setSelectedStageID(null));
                  }}
                >
                  <ListItemDecorator sx={{ color: "inherit" }}>
                    <i className="fa-solid fa-trash"></i>
                  </ListItemDecorator>
                  Delete
                </MenuItem>
              </Menu>
            </Dropdown>
          </Box>
          <Input
            sx={{ boxShadow: "none" }}
            variant="outlined"
            startDecorator="Name:"
            value={_stage.name}
            onChange={(e) => setStage((s) => ({ ...s, name: e.target.value }))}
            onBlur={save}
          />
          <Box>
            <Select
              color={_stage.pipelineID !== pipelineID ? "warning" : undefined}
              startDecorator="Pipeline:"
              value={_stage.pipelineID}
              sx={{ boxShadow: "none" }}
              onChange={(e, v) => {
                setStage((s) => ({
                  ...s,
                  pipelineID: v as number,
                }));
                updateStage({
                  id: stageID,
                  body: {
                    ..._stage,
                    pipelineID: v as number,
                  },
                });
              }}
            >
              {pipelines?.map((p) => (
                <Option key={p.id} value={p.id}>
                  {p.name}
                </Option>
              ))}
            </Select>
            {_stage.pipelineID !== pipelineID ? (
              <Typography
                startDecorator={
                  <i className="fa-solid fa-triangle-exclamation"></i>
                }
                color="warning"
                level="body-xs"
              >
                Stage is Inherited
              </Typography>
            ) : (
              []
            )}
          </Box>
          <Box>
            <Select
              startDecorator="Available:"
              sx={{ boxShadow: "none" }}
              value={null}
              onChange={(e, v) => {
                setStage((s) => ({
                  ...s,
                  available: [...available, v].join(","),
                }));
                updateStage({
                  id: stageID,
                  body: {
                    ..._stage,
                    available: [...available, v].join(","),
                  },
                });
              }}
            >
              <Option value={"*"}>
                <Typography startDecorator={"*"}>All</Typography>
              </Option>
              {pipeline?.stages
                .filter((e) => !available?.includes(`${e.id}`))
                .map((s) => (
                  <Option value={s.id} key={s.id}>
                    <Typography
                      startDecorator={<img height="18px" src={s.icon} />}
                    >
                      {s.name}
                    </Typography>
                  </Option>
                ))}
            </Select>
            {available.length ? (
              <List size="sm" variant="outlined">
                {available?.map((a) => {
                  const stage = pipeline.stages.find((s) => `${s.id}` === a);
                  return (
                    <ListItem key={a}>
                      <Typography
                        sx={{ flexGrow: 1 }}
                        startDecorator={<img src={stage?.icon} height="14px" />}
                        level="body-sm"
                      >
                        {stage?.name ?? "* All"}
                      </Typography>
                      <IconButton
                        size="sm"
                        onClick={() => {
                          setStage((s) => ({
                            ...s,
                            available: available
                              .filter((e) => e !== a)
                              .join(","),
                          }));
                          updateStage({
                            id: stageID,
                            body: {
                              ..._stage,
                              available: available
                                .filter((e) => e !== a)
                                .join(","),
                            },
                          });
                        }}
                      >
                        <i className="fa-solid fa-xmark"></i>
                      </IconButton>
                    </ListItem>
                  );
                })}
              </List>
            ) : (
              []
            )}
          </Box>
          <Box>
            <Select
              startDecorator="Excluded:"
              sx={{ boxShadow: "none" }}
              value={null}
              onChange={(e, v) => {
                setStage((s) => ({
                  ...s,
                  excluded: [...excluded, v].join(","),
                }));
                updateStage({
                  id: stageID,
                  body: {
                    ..._stage,
                    excluded: [...excluded, v].join(","),
                  },
                });
              }}
            >
              <Option value={"*"}>
                <Typography startDecorator={"*"}>All</Typography>
              </Option>
              {pipeline?.stages
                .filter((e) => !excluded?.includes(`${e.id}`))
                .map((s) => (
                  <Option value={s.id} key={s.id}>
                    <Typography
                      startDecorator={<img height="18px" src={s.icon} />}
                    >
                      {s.name}
                    </Typography>
                  </Option>
                ))}
            </Select>
            {excluded.length ? (
              <List size="sm" variant="outlined">
                {excluded?.map((a) => {
                  const stage = pipeline.stages.find((s) => `${s.id}` === a);
                  return (
                    <ListItem key={a}>
                      <Typography
                        sx={{ flexGrow: 1 }}
                        startDecorator={<img src={stage?.icon} height="14px" />}
                        level="body-sm"
                      >
                        {stage?.name ?? "* All"}
                      </Typography>
                      <IconButton
                        size="sm"
                        onClick={() => {
                          setStage((s) => ({
                            ...s,
                            excluded: excluded.filter((e) => e !== a).join(","),
                          }));
                          updateStage({
                            id: stageID,
                            body: {
                              ..._stage,
                              excluded: excluded
                                .filter((e) => e !== a)
                                .join(","),
                            },
                          });
                        }}
                      >
                        <i className="fa-solid fa-xmark"></i>
                      </IconButton>
                    </ListItem>
                  );
                })}
              </List>
            ) : (
              []
            )}
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Sheet
              variant="outlined"
              sx={{
                background: "#eceff1",
                padding: 1,
                borderStyle: "dashed",
                borderRadius: 8,
                height: 56,
                width: 56,
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {_stage.icon ? (
                <img src={_stage.icon} style={{ height: 32 }} />
              ) : (
                <i className="fa-solid fa-image"></i>
              )}
            </Sheet>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              {_stage?.icon ? (
                <Chip
                  color="danger"
                  onClick={(e) => {
                    setStage((s) => ({
                      ...s,
                      icon: "",
                    }));
                    updateStage({
                      id: stageID,
                      body: {
                        ..._stage,
                        icon: "",
                      },
                    });
                  }}
                  size="sm"
                >
                  Remove Icon
                </Chip>
              ) : (
                <Typography level="body-sm">Upload an icon</Typography>
              )}
              <Upload
                onUpload={(e) => {
                  setStage((s) => ({
                    ...s,
                    icon: e,
                  }));
                  updateStage({
                    id: stageID,
                    body: {
                      ..._stage,
                      icon: e,
                    },
                  });
                }}
              />
            </Box>
          </Box>
          <ColorPicker
            key={_stage.id}
            color={_stage.color}
            onChange={(e) => {
              setStage((s) => ({ ...s, color: e }));
              updateStage({ id: stageID, body: { ..._stage, color: e } });
            }}
          />
          <Checkbox
            onChange={(e) => {
              setStage((s) => ({ ...s, primary: e.target.checked }));
              updateStage({
                id: stageID,
                body: { ..._stage, primary: e.target.checked },
              });
            }}
            checked={_stage.primary ?? false}
            label="Entry Point"
          />
          <Checkbox
            onChange={(e) => {
              setStage((s) => ({ ...s, terminus: e.target.checked }));
              updateStage({
                id: stageID,
                body: { ..._stage, terminus: e.target.checked },
              });
            }}
            checked={_stage.terminus ?? false}
            label="Terminus"
          />
          <Checkbox
            onChange={(e) => {
              setStage((s) => ({ ...s, messaging: e.target.checked }));
              updateStage({
                id: stageID,
                body: { ..._stage, messaging: e.target.checked },
              });
            }}
            checked={_stage.messaging ?? false}
            label="Messaging"
          />
          <Checkbox
            onChange={(e) => {
              setStage((s) => ({ ...s, manual: e.target.checked }));
              updateStage({
                id: stageID,
                body: { ..._stage, manual: e.target.checked },
              });
            }}
            checked={_stage.manual ?? false}
            label="Manual Action"
          />
          <Checkbox
            onChange={(e) => {
              setStage((s) => ({ ...s, silent: e.target.checked }));
              updateStage({
                id: stageID,
                body: { ..._stage, silent: e.target.checked },
              });
            }}
            checked={_stage.silent ?? false}
            label="Stage is Silent"
          />
          <Checkbox
            onChange={(e) => {
              setStage((s) => ({ ...s, nudgeable: e.target.checked }));
              updateStage({
                id: stageID,
                body: { ..._stage, nudgeable: e.target.checked },
              });
            }}
            checked={_stage.nudgeable ?? false}
            label="Nudgeable"
          />
          <Checkbox
            onChange={(e) => {
              setStage((s) => ({
                ...s,
                acknowledgmentRequired: e.target.checked,
              }));
              updateStage({
                id: stageID,
                body: { ..._stage, acknowledgmentRequired: e.target.checked },
              });
            }}
            checked={_stage.acknowledgmentRequired ?? false}
            label="Ack Required"
          />
          <Divider>Event Status</Divider>
          <EventStatus key={_stage?.id} stage={_stage} />
          <Divider>Interactors</Divider>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            {_stage.interactors?.map((interactor) => {
              return (
                <InteractorBox
                  pipeline={pipeline}
                  key={interactor.id}
                  interactor={interactor}
                />
              );
            })}
          </Box>
          <Box
            sx={{
              position: "sticky",
              bottom: "-16px",
              left: "-16px",
              background: "white",
              width: "100%",
              zIndex: 99,
              p: 1,
              borderTop: "solid 1px #E1E1E1",
            }}
            onClick={() => createInteractor({ stageID: _stage.id })}
          >
            <Button fullWidth>+ Interactor</Button>
          </Box>
        </Box>
      ) : (
        []
      )}
    </Box>
  );
}

function EventStatus({ stage }: { stage: Partial<Stage> }) {
  let config;

  try {
    config = JSON.parse(stage.config ?? "{}");
  } catch (error) {
    console.log(error);
  }

  const [updateStage] = useUpdateStageMutation();
  const [_eventStatus, setEventStatus] =
    useState<Partial<EventStatus_Entity>>(config);

  const save = () => {
    updateStage({
      id: stage.id ?? 0,
      body: { ...stage, config: JSON.stringify(_eventStatus) },
    });
  };

  return (
    <Sheet
      variant="outlined"
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        borderRadius: 4,
        p: 0.5,
      }}
    >
      <Input
        value={_eventStatus.title}
        size="sm"
        sx={{ boxShadow: "none", background: "white" }}
        startDecorator="Title:"
        onChange={(e) =>
          setEventStatus((s) => ({ ...s, title: e.target.value }))
        }
        onBlur={save}
      />
      <Textarea
        value={_eventStatus.greeting}
        size="sm"
        sx={{ boxShadow: "none", background: "white" }}
        startDecorator="Greetings"
        onChange={(e) =>
          setEventStatus((s) => ({ ...s, greeting: e.target.value }))
        }
        onBlur={save}
      />
      <Checkbox
        checked={_eventStatus.clearImportantChanges ?? false}
        size="sm"
        label="Clear important changes"
        onChange={(e) => {
          setEventStatus((s) => ({
            ...s,
            clearImportantChanges: e.target.checked,
          }));
          updateStage({
            id: stage.id ?? 0,
            body: {
              ...stage,
              config: JSON.stringify({
                ..._eventStatus,
                clearImportantChanges: e.target.checked,
              }),
            },
          });
        }}
      />
      <Checkbox
        checked={_eventStatus.clearCriticalChanges ?? false}
        size="sm"
        label="Clear critical changes"
        onChange={(e) => {
          setEventStatus((s) => ({
            ...s,
            clearCriticalChanges: e.target.checked,
          }));
          updateStage({
            id: stage.id ?? 0,
            body: {
              ...stage,
              config: JSON.stringify({
                ..._eventStatus,
                clearCriticalChanges: e.target.checked,
              }),
            },
          });
        }}
      />
    </Sheet>
  );
}

function InteractorBox({
  interactor,
  pipeline,
}: {
  interactor: Interactor;
  pipeline: Pipeline;
}) {
  const [_interactor, setInteractor] = useState<Interactor_Entity>(interactor);
  const [updateInteractor] = useUpdateInteractorMutation();
  const [deleteInteractor] = useDeleteInteractorMutation();
  const [collapse, setCollapse] = useState(false);

  useEffect(() => {
    setInteractor(interactor);
  }, [interactor]);

  const save = () => {
    if (interactor.id)
      updateInteractor({ id: interactor.id, body: _interactor });
  };

  return (
    <Sheet
      variant="outlined"
      key={interactor.id}
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 0.5,
        borderRadius: 4,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          cursor: "pointer",
          alignItems: "center",
          p: 1,
        }}
        onClick={() => setCollapse((s) => !s)}
      >
        <Typography
          startDecorator={
            _interactor.icon ? (
              <i
                style={{ color: _interactor.color }}
                className={_interactor.icon}
              />
            ) : undefined
          }
          level="body-sm"
          endDecorator={<Id>{_interactor.id}</Id>}
          sx={{ color: "black", fontWeight: 600 }}
        >
          {_interactor.name}
        </Typography>
        <i
          style={{
            transform: !collapse ? "rotate(180deg)" : undefined,
            transition: "transform .3s",
          }}
          className="fa-solid fa-chevron-up"
        ></i>
      </Box>
      <Collapse in={collapse}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1, p: 0.5 }}>
          <Input
            value={_interactor.name}
            sx={{ boxShadow: "none", background: "white" }}
            variant="outlined"
            size="sm"
            startDecorator="Name:"
            onChange={(e) =>
              setInteractor((i) => ({ ...i, name: e.target.value }))
            }
            onBlur={save}
          />
          <Textarea
            sx={{ boxShadow: "none", background: "white" }}
            value={_interactor.description}
            onChange={(e) =>
              setInteractor((i) => ({ ...i, description: e.target.value }))
            }
            variant="outlined"
            size="sm"
            startDecorator="Description:"
            onBlur={save}
          />
          <Select
            size="sm"
            startDecorator="Stage:"
            value={_interactor.stageID}
            sx={{ boxShadow: "none", background: "white" }}
            onChange={(e, v) => {
              setInteractor((i) => ({ ...i, stageID: v as number }));
              updateInteractor({
                id: interactor.id,
                body: { ..._interactor, stageID: v as number },
              });
            }}
          >
            {pipeline?.stages.map((s) => (
              <Option value={s.id} key={s.id}>
                <Typography startDecorator={<img height="18px" src={s.icon} />}>
                  {s.name}
                </Typography>
              </Option>
            ))}
          </Select>
          <Select
            size="sm"
            startDecorator="Kind:"
            value={_interactor.kind}
            sx={{ boxShadow: "none", background: "white" }}
            onChange={(e, v) => {
              setInteractor((i) => ({ ...i, kind: v as string }));
              updateInteractor({
                id: interactor.id,
                body: { ..._interactor, kind: v as string },
              });
            }}
          >
            <Option value="button">Button</Option>
            <Option value="text">Text</Option>
            <Option value="signature">Signature</Option>
            <Option value="file">File Upload</Option>
            <Option value="download">File Download</Option>
            <Option value="checkbox">Checkbox</Option>
          </Select>
          <Input
            sx={{ boxShadow: "none", background: "white" }}
            variant="outlined"
            value={_interactor.icon}
            size="sm"
            startDecorator="Icon Class:"
            onBlur={save}
            onChange={(e) =>
              setInteractor((i) => ({ ...i, icon: e.target.value }))
            }
          />
          <Select
            size="sm"
            startDecorator="Segue:"
            value={_interactor.segueID}
            sx={{ boxShadow: "none", background: "white" }}
            onChange={(e, v) => {
              setInteractor((i) => ({ ...i, segueID: v as number }));
              updateInteractor({
                id: interactor.id,
                body: { ..._interactor, segueID: v as number },
              });
            }}
          >
            {pipeline?.stages.map((s) => (
              <Option value={s.id} key={s.id}>
                <Typography startDecorator={<img height="18px" src={s.icon} />}>
                  {s.name}
                </Typography>
              </Option>
            ))}
          </Select>
          <Input
            sx={{ boxShadow: "none", background: "white" }}
            variant="outlined"
            value={_interactor.order}
            size="sm"
            type="number"
            onBlur={save}
            startDecorator="Order:"
            onChange={(e) =>
              setInteractor((i) => ({ ...i, order: parseInt(e.target.value) }))
            }
          />
          <Checkbox
            onChange={(e) => {
              setInteractor((i) => ({ ...i, mandatory: e.target.checked }));
              updateInteractor({
                id: interactor.id,
                body: { ..._interactor, mandatory: e.target.checked },
              });
            }}
            size="sm"
            label="Mandatory"
            checked={_interactor.mandatory ?? false}
          />
          <ColorPicker
            color={_interactor.color}
            onChange={(e) => {
              setInteractor((i) => ({ ...i, color: e }));
              updateInteractor({
                id: interactor.id,
                body: { ..._interactor, color: e },
              });
            }}
          />
          <Link
            color="danger"
            level="body-xs"
            startDecorator={<i className="fa-solid fa-trash"></i>}
            onClick={() => {
              deleteInteractor(_interactor.id);
            }}
          >
            Delete
          </Link>
        </Box>
      </Collapse>
    </Sheet>
  );
}

function StageNode({ data }: { data: any }) {
  const stage: Stage_Entity = data?.stage;
  const pipelineID = useSelector(selectedPipelineIDSelector);
  const { eventStatuses, isLoading } = useEventStatuses();

  const eventStatus = eventStatuses?.find((s) => s.mercuryStageID === stage.id);
  let config;

  try {
    config = JSON.parse(stage.config ?? "{}");
  } catch (error) {
    console.log(error);
  }

  return (
    <>
      <Handle type="target" position={Position.Left} />
      <Box
        sx={{
          width: 120,
          height: 25,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: pipelineID === stage.pipelineID ? undefined : "white",
          color: pipelineID === stage.pipelineID ? undefined : stage.color,
          borderRadius: 16,
          border:
            pipelineID === stage.pipelineID
              ? undefined
              : `solid 1px ${stage.color}`,
        }}
      >
        <Typography
          level="body-sm"
          noWrap
          sx={{ color: "inherit" }}
          startDecorator={
            <img
              src={stage.icon}
              style={{
                height: 14,
                filter:
                  pipelineID === stage.pipelineID
                    ? "brightness(0) invert(1)"
                    : undefined,
              }}
            />
          }
          endDecorator={
            !config?.title ? (
              <i className="fa-solid fa-triangle-exclamation fa-shake"></i>
            ) : undefined
          }
        >
          {stage.name}
        </Typography>
      </Box>
      <Handle type="source" position={Position.Right} />
    </>
  );
}

function getColorFilter(hexColor: string) {
  // Convert the hex color to RGB
  const r = parseInt(hexColor.slice(1, 3), 16);
  const g = parseInt(hexColor.slice(3, 5), 16);
  const b = parseInt(hexColor.slice(5, 7), 16);

  // Calculate the hue value
  const hue =
    (Math.atan2(Math.sqrt(3) * (g - b), 2 * r - g - b) * 180) / Math.PI;

  // Calculate the saturation value
  const saturation = Math.sqrt((r - g) ** 2 + (r - b) * (g - b) + 1) / 2.0;

  // Calculate the brightness value
  const brightness = (r + g + b) / 3 / 255;

  // Create the filter string
  const filter = `sepia(100%) hue-rotate(${hue}deg) saturate(${
    saturation * 100
  }%) brightness(${brightness * 100}%)`;

  return filter;
}
