import { Channel } from "../channel";
import { Inspector } from "../inspector";
import { Interactor } from "../interactor";
import { Job } from "../job";

/**
 * Stage_Entity type guard.
 *
 * @param {any} stageJson Stage object from API
 * @returns {boolean} Return true if type is Stage_Entity
 */
function validator(stageJson: unknown): stageJson is Stage_Entity {
  if (typeof stageJson === "object" && stageJson != null) {
    Stage_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(stageJson).includes(element))
        throw new Error("Field ${element} is null or undefined");
    });
  }
  return true;
}

export default class Stage_Entity {
  static requiredFields = [
    "acknowledgmentRequired",
    "interactors",
    "inspectors",
    "channels",
    "jobs",
    "jobIDs",
    "jobCount",
    "acknowledged",
  ];

  constructor(stageJson: unknown) {
    if (validator(stageJson)) {
      this.id = stageJson.id;
      this.pipelineID = stageJson.pipelineID;
      this.name = stageJson.name;
      this.icon = stageJson.icon;
      this.color = stageJson.color;
      this.primary = stageJson.primary;
      this.terminus = stageJson.terminus;
      this.messaging = stageJson.messaging;
      this.manual = stageJson.manual;
      this.nudgeable = stageJson.nudgeable;
      this.silent = stageJson.silent;
      this.available = stageJson.available;
      this.excluded = stageJson.excluded;
      this.position = stageJson.position;
      this.config = stageJson.config;
      this.acknowledgmentRequired = stageJson.acknowledgmentRequired;
      this.interactors = stageJson.interactors;
      this.inspectors = stageJson.inspectors;
      this.channels = stageJson.channels;
      this.jobs = stageJson.jobs;
      this.jobIDs = stageJson.jobIDs;
      this.jobCount = stageJson.jobCount;
      this.nudgeCount = stageJson.nudgeCount;
      this.acknowledged = stageJson.acknowledged;
      this.createdAt = stageJson.createdAt;
      this.createdBy = stageJson.createdBy;
      this.relationID = stageJson.relationID;
      this.data = stageJson.data;
    } else {
      throw new Error(
        "Failed to create new instance of ${ Stage_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  pipelineID?: number;
  name?: string;
  icon?: string;
  color?: string;
  primary?: boolean;
  terminus?: boolean;
  messaging?: boolean;
  manual?: boolean;
  nudgeable?: boolean;
  silent?: boolean;
  available?: string;
  excluded?: string;
  position?: string;
  config?: string;
  acknowledgmentRequired: boolean;
  interactors: Array<Interactor>;
  inspectors: Array<Inspector>;
  channels: Array<Channel>;
  jobs: Array<Job>;
  jobIDs: Array<number>;
  jobCount: number;
  nudgeCount?: number;
  acknowledged: boolean;
  createdAt?: string;
  createdBy?: string;
  relationID?: number;
  data?: string;
}
