/**
 * @param noteTypeJson
 */
function validator(noteTypeJson: any) {
  NoteType_Entity.requiredFields.forEach((element) => {
    if (!Object.keys(noteTypeJson).includes(element))
      console.log("Field ${element} is null or undefined");
  });
}

export default class NoteType_Entity {
  static requiredFields = ["id", "createdAt", "updatedAt", ""];

  constructor(noteTypeJson: any) {
    try {
      validator(noteTypeJson);

      this.id = noteTypeJson.id;
      this.name = noteTypeJson.name;
      this.kind = noteTypeJson.kind;
      this.data = noteTypeJson.data;
      this.organizationID = noteTypeJson.organizationID;
      this.createdAt = noteTypeJson.createdAt;
      this.updatedAt = noteTypeJson.updatedAt;
    } catch (e) {
      throw new Error(
        "Failed to create new instance of ${ NoteType_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  name?: string;
  kind?: string;
  data?: string;
  organizationID?: number;
  createdAt: string;
  updatedAt: string;
}
