import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { Instrument_Entity } from "../../entities/instrument";
import { museApi } from "../api/museApi";

const instrumentsAdapter = createEntityAdapter<Instrument_Entity>();
const initialState = instrumentsAdapter.getInitialState();

export const instrumentEndpoints = museApi.injectEndpoints({
  endpoints: (build) => ({
    getInstruments: build.query<EntityState<Instrument_Entity>, void>({
      query: () => `instruments`,
      transformResponse: (responseData: Instrument_Entity[]) => {
        return instrumentsAdapter.setAll(initialState, responseData);
      },
      providesTags: ["instruments"],
    }),
    getInstrument: build.query<Instrument_Entity, number>({
      query: (id) => `instruments/${id}`,
      providesTags: (result, error, id) => [{ type: "instruments", id }],
    }),
    createInstrument: build.mutation<
      Instrument_Entity,
      Partial<Instrument_Entity>
    >({
      query: (body) => ({
        method: "POST",
        body,
        url: `instruments`,
      }),
      invalidatesTags: ["instruments"],
    }),
    updateInstrument: build.mutation<
      void,
      { id: number; body: Partial<Instrument_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `instruments/${args.id}`,
      }),
      invalidatesTags: ["instruments"],
    }),
    deleteInstrument: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `instruments/${id}`,
      }),
      invalidatesTags: ["instruments"],
    }),
  }),
});

export const {
  useGetInstrumentQuery,
  useGetInstrumentsQuery,
  useCreateInstrumentMutation,
  useDeleteInstrumentMutation,
  useUpdateInstrumentMutation,
} = instrumentEndpoints;
