/**
 * Interactor_Entity type guard.
 *
 * @param {any} interactorJson Interactor object from API
 * @returns {boolean} Return true if type is Interactor_Entity
 */

/**
 * @param interactorJson
 */
function validator(interactorJson: any) {
  Interactor_Entity.requiredFields.forEach((element) => {
    if (!Object.keys(interactorJson).includes(element))
      console.log(`Field ${element} is null or undefined`);
  });
}

export default class Interactor_Entity {
  static requiredFields = ["postalURL", ""];

  constructor(interactorJson: any) {
    try {
      validator(interactorJson);

      this.id = interactorJson.id;
      this.name = interactorJson.name;
      this.icon = interactorJson.icon;
      this.color = interactorJson.color;
      this.mandatory = interactorJson.mandatory;
      this.stageID = interactorJson.stageID;
      this.title = interactorJson.title;
      this.description = interactorJson.description;
      this.kind = interactorJson.kind;
      this.segueID = interactorJson.segueID;
      this.data = interactorJson.data;
      this.order = interactorJson.order;
      this.postalURL = interactorJson.postalURL;
    } catch (e) {
      throw new Error(
        "Failed to create new instance of ${ Interactor_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  name?: string;
  icon?: string;
  color?: string;
  mandatory?: boolean;
  stageID?: number;
  title?: string;
  description?: string;
  kind?: string;
  segueID?: number;
  data?: string;
  order?: number;
  postalURL?: string;
}
