import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { Configuration_Entity } from "../../entities/configuration";
import { autopilotApi } from "../api/autopilotApi";

const configurationsAdapter = createEntityAdapter<Configuration_Entity>();
const initialState = configurationsAdapter.getInitialState();

export const configurationEndpoints = autopilotApi.injectEndpoints({
  endpoints: (build) => ({
    getConfigurations: build.query<EntityState<Configuration_Entity>, void>({
      query: () => `configurations`,
      transformResponse: (responseData: Configuration_Entity[]) => {
        return configurationsAdapter.setAll(initialState, responseData);
      },
      providesTags: ["configurations"],
    }),
    getConfiguration: build.query<Configuration_Entity, number>({
      query: (id) => `configurations/${id}`,
      providesTags: (result, error, id) => [{ type: "configurations", id }],
    }),
    createConfiguration: build.mutation<
      Configuration_Entity,
      Partial<Configuration_Entity>
    >({
      query: (body) => ({
        method: "POST",
        body,
        url: `configurations`,
      }),
      invalidatesTags: ["configurations"],
    }),
    updateConfiguration: build.mutation<
      void,
      { id: number; body: Partial<Configuration_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `configurations/${args.id}`,
      }),
      invalidatesTags: ["configurations"],
    }),
    deleteConfiguration: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `configurations/${id}`,
      }),
      invalidatesTags: ["configurations"],
    }),
  }),
});

export const {
  useGetConfigurationQuery,
  useGetConfigurationsQuery,
  useCreateConfigurationMutation,
  useDeleteConfigurationMutation,
  useUpdateConfigurationMutation,
} = configurationEndpoints;
