import {
  CircularProgress,
  Grid,
  Hidden,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useGetCurrentUserAppsQuery } from "@nerdjs/account-kit";
import { useState, MouseEvent, ReactElement } from "react";
import { Apps } from "@mui/icons-material";
import { RouterConfig } from "../config/routerConfig";
import { useTranslation } from "react-i18next";
import { NerdAppsMenu, StyledMenu } from "@nerdjs/nerd-ui";
import { Button, IconButton } from "@mui/joy";

/**
 *
 * @returns {ReactElement} NerdAppsMenuWrapper
 */
export default function NerdAppsMenuWrapper(): ReactElement {
  const { data: apps, isFetching } = useGetCurrentUserAppsQuery();
  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & HTMLButtonElement) | null
  >(null);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const { t } = useTranslation("entities");
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <>
      <Hidden smUp>
        <IconButton
          variant="plain"
          color="neutral"
          size="sm"
          onClick={handleClick}
          sx={{ color: "white" }}
        >
          <i className="fa-solid fa-grid-2"></i>
        </IconButton>
      </Hidden>
      <Hidden smDown>
        <Button
          sx={{ color: "white" }}
          onClick={handleClick}
          variant="plain"
          color="neutral"
          size="sm"
          startDecorator={<i className="fa-solid fa-grid-2"></i>}
        >
          <Hidden smDown>{t("app.entity")}</Hidden>
        </Button>
      </Hidden>
      <StyledMenu
        id={id}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "310px",
          }}
        >
          {isFetching ? (
            <Grid
              container
              sx={{ marginTop: 0, minHeight: "398px" }}
              justifyContent={"center"}
              direction={"column"}
            >
              <Grid item alignSelf={"center"}>
                <CircularProgress />
              </Grid>
            </Grid>
          ) : (
            apps && <NerdAppsMenu homePath={RouterConfig.root} apps={apps} />
          )}
        </div>
      </StyledMenu>
    </>
  );
}
