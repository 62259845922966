import { nerdNetworkBaseQuery } from "@nerdjs/nerd-network";
import { createApi } from "@reduxjs/toolkit/query/react";

export const museApi = createApi({
  reducerPath: "museApi",
  baseQuery: (args, api) =>
    nerdNetworkBaseQuery(args, api, { service: "muse" }),
  endpoints: () => ({}),
  tagTypes: ["instruments", "families", "sections", "sectionInstruments"],
});
