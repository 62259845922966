import { Box } from "@mui/material";
import {
  DataGridPremium,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-premium";
import { useDispatch } from "react-redux";
import { setFormOpen, setSelectedSectionID } from "../../reducers/rhapsody";
import { useSections } from "../../redux/section/sectionHooks";
import Id from "../id/id";
import { useFamilies } from "../../redux/family/familyHooks";
import { Section_Entity } from "../../entities/section";
import { useInstruments } from "../../redux/instrument/instrumentHooks";

/**
 * Section
 *
 * @returns {null} Section
 */
export default function Sections() {
  const { sections } = useSections();
  const { familiesMap } = useFamilies();
  const { instrumentsMap } = useInstruments();

  const dispatch = useDispatch();
  if (!familiesMap) return <Box />;

  const _sections =
    sections?.reduce<(Section_Entity & { familyName: string })[]>((a, v) => {
      a.push({ ...v, familyName: familiesMap[v.familyID ?? 0]?.name ?? "" });
      return a;
    }, []) ?? [];

  _sections.sort((a, b) => {
    if (a.familyID === b.familyID) {
      return (a.pos ?? 0) - (b.pos ?? 0);
    }
    return (a.familyPos ?? 0) - (b.familyPos ?? 0);
  });

  return (
    <DataGridPremium
      rows={_sections ?? []}
      density="compact"
      columns={[
        {
          field: "id",
          headerName: "ID",
          type: "number",
          width: 60,
          renderCell: (p) => {
            if (p.id === -1) return <Box />;
            return (
              <Id startDecorator={<i className="fa-solid fa-trumpet"></i>}>
                {p.id}
              </Id>
            );
          },
        },
        { field: "name", headerName: "Name", flex: 1 },
        { field: "shorthand", headerName: "Shorthand", width: 80 },
        {
          field: "defaultInstrumentID",
          headerName: "Default Instrument",
          width: 250,
          valueGetter: (e) => instrumentsMap && instrumentsMap[e.value]?.name,
        },
        { field: "pos", headerName: "Pos in Family", width: 100 },
        {
          field: "familyName",
          headerName: "Family",
          width: 250,
        },
      ]}
      slots={{ toolbar: QuickSearchToolbar }}
      onRowClick={(e) => {
        dispatch(setSelectedSectionID(e.row.id));
        dispatch(setFormOpen({ isOpen: true, formID: "section" }));
      }}
    />
  );
}

function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
        display: "flex",
      }}
    >
      <GridToolbarQuickFilter
        variant="outlined"
        size="small"
        sx={{ flex: 1 }}
        fullWidth
      />
    </Box>
  );
}
