import { useMemo } from "react";
import { NoteType } from "../../entities/noteType";
import { useGetNoteTypeQuery, useGetNoteTypesQuery } from "./noteTypeEndpoints";

export function useNoteType(
  noteTypeID: number | undefined | null,
  opt?: { skip?: boolean }
) {
  const noteTypeQuery = useGetNoteTypeQuery(noteTypeID ?? 0, {
    skip: !noteTypeID || opt?.skip,
  });
  const noteTypeEntity = noteTypeQuery.data;

  return useMemo(() => {
    const ret: typeof noteTypeQuery & {
      noteType?: NoteType | undefined;
    } = {
      ...noteTypeQuery,
    };
    if (noteTypeEntity) ret.noteType = new NoteType(noteTypeEntity);
    return ret;
  }, [noteTypeEntity]);
}

export function useNoteTypes() {
  const noteTypeQuery = useGetNoteTypesQuery();
  const noteTypeEntitiesMap = noteTypeQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof noteTypeQuery & {
      noteTypes?: NoteType[];
      noteTypesMap?: { [id: number]: NoteType };
    } = {
      ...noteTypeQuery,
      noteTypes: [],
      noteTypesMap: {},
    };
    if (noteTypeEntitiesMap) {
      const noteTypes: NoteType[] = [];
      const noteTypesMap: { [id: number]: NoteType } = {};

      for (const key in noteTypeEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(noteTypeEntitiesMap, key)) {
          const c = noteTypeEntitiesMap[key];
          if (c) {
            const noteType = new NoteType(c);
            noteTypes.push(noteType);
            if (noteType?.id) noteTypesMap[noteType.id] = noteType;
          }
        }
      }
      ret.noteTypes = noteTypes;
      ret.noteTypesMap = noteTypesMap;
    }

    return ret;
  }, [noteTypeEntitiesMap]);
}
