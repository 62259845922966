import Family_Entity from "./family";
export default class Family extends Family_Entity {
  static fromList(familysJSON: unknown): Array<Family> {
    const familys: Family[] = [];
    if (familysJSON)
      Array.isArray(familysJSON) &&
        familysJSON.forEach((familyJSON) => {
          familys.push(new Family(familyJSON));
        });
    return familys;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
