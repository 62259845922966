/**
 * Pipeline_Entity type guard.
 *
 * @param {any} pipelineJson Pipeline object from API
 * @returns {boolean} Return true if type is Pipeline_Entity
 */

import { Stage } from "../stage";

/**
 * @param pipelineJson
 */
function validator(pipelineJson: any) {
  Pipeline_Entity.requiredFields.forEach((element) => {
    if (!Object.keys(pipelineJson).includes(element))
      console.log(`Field ${element} is null or undefined`);
  });
}

export default class Pipeline_Entity {
  static requiredFields = ["stages", ""];

  constructor(pipelineJson: any) {
    try {
      validator(pipelineJson);

      this.id = pipelineJson.id;
      this.name = pipelineJson.name;
      this.organizationID = pipelineJson.organizationID;
      this.public = pipelineJson.public;
      this.inherit = pipelineJson.inherit;
      this.stages = pipelineJson.stages;
      this.excludedStageIDs = pipelineJson.excludedStageIDs;
      this.excludedInteractorIDs = pipelineJson.excludedInteractorIDs;
    } catch (e) {
      throw new Error(
        "Failed to create new instance of ${ Pipeline_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  name?: string;
  organizationID?: number;
  public?: boolean;
  inherit?: string;
  excludedStageIDs: string;
  excludedInteractorIDs: string;
  stages: Array<Stage>;
}
