import { useMemo } from "react";
import { useGetPipelineQuery, useGetPipelinesQuery } from "./pipelineEndpoints";
import { Pipeline } from "../../entities/pipeline";

export function usePipeline(pipelineID: number | undefined | null) {
  const pipelineQuery = useGetPipelineQuery(pipelineID ?? 0, {
    skip: (pipelineID ?? 0) <= 0,
  });
  const pipelineEntity = pipelineQuery.data;

  return useMemo(() => {
    const ret: typeof pipelineQuery & { pipeline?: Pipeline | undefined } = {
      ...pipelineQuery,
    };
    if (pipelineEntity) ret.pipeline = new Pipeline(pipelineEntity);
    return ret;
  }, [pipelineEntity]);
}

export function usePipelines() {
  const pipelineQuery = useGetPipelinesQuery();
  const pipelineEntitiesMap = pipelineQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof pipelineQuery & {
      pipelines?: Pipeline[];
      pipelinesMap?: { [id: number]: Pipeline };
    } = {
      ...pipelineQuery,
      pipelines: [],
      pipelinesMap: {},
    };
    if (pipelineEntitiesMap) {
      const pipelines: Pipeline[] = [];
      const pipelinesMap: { [id: number]: Pipeline } = {};

      for (const key in pipelineEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(pipelineEntitiesMap, key)) {
          const c = pipelineEntitiesMap[key];
          const pipeline = new Pipeline(c);
          pipelines.push(pipeline);
          pipelinesMap[pipeline.id] = pipeline;
        }
      }
      ret.pipelines = pipelines;
      ret.pipelinesMap = pipelinesMap;
    }

    return ret;
  }, [pipelineEntitiesMap]);
}
