import { Box } from "@mui/system";
import { ReactElement, useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import Pipelines from "../hooks/pipelines/pipelines";
import {
  selectedPipelineIDSelector,
  setFormOpen,
  setSelectedPipelineID,
} from "../reducers/rhapsody";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { Button, Typography } from "@mui/joy";

/**
 *
 * @returns {ReactElement} Nerd route
 */
export default function PipelinesPage(): ReactElement {
  const params = useParams();
  const pipelineID = useSelector(selectedPipelineIDSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (params.id && !pipelineID) {
      dispatch(setSelectedPipelineID(parseInt(params.id)));
    }

    if (!params.id) {
      dispatch(setSelectedPipelineID(null));
    }
  }, [params, pipelineID]);

  if (pipelineID)
    return (
      <Box sx={{ flex: 1, display: "flex" }}>
        <Outlet />
      </Box>
    );
  return (
    <Box
      sx={{
        p: 2,
        display: "flex",
        gap: 2,
        flexDirection: "column",
        maxWidth: 1600,
        marginLeft: "auto",
        marginRight: "auto",
        width: "100%",
      }}
    >
      <Helmet>
        <title>Rhapsody | Pipelines</title>
      </Helmet>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography sx={{ fontWeight: 800, fontSize: 24 }} level="h4">
          Pipelines
        </Typography>
        <Button
          onClick={() =>
            dispatch(setFormOpen({ isOpen: true, formID: "pipeline" }))
          }
          size="sm"
        >
          + Add Pipeline
        </Button>
      </Box>
      <Pipelines />
    </Box>
  );
}
