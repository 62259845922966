import { Option } from "../option";

/**
 * Actionnable_Entity type guard.
 *
 * @param {any} actionnableJson Actionnable object from API
 * @returns {boolean} Return true if type is Actionnable_Entity
 */
function validator(
  actionnableJson: unknown
): actionnableJson is Actionnable_Entity {
  if (typeof actionnableJson === "object" && actionnableJson != null) {
    Actionnable_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(actionnableJson).includes(element))
        console.error(`Field ${element} is null or undefined`);
      // throw new Error(`Field ${element} is null or undefined`);
    });
  }
  return true;
}

export default class Actionnable_Entity {
  static requiredFields = [
    "id",
    "Name",
    "description",
    "icon",
    "parentActionID",
    "callback",
    "createdAt",
    "updatedAt",
  ];

  constructor(actionnableJson: unknown) {
    if (validator(actionnableJson)) {
      this.id = actionnableJson.id;
      this.name = actionnableJson.name;
      this.description = actionnableJson.description;
      this.icon = actionnableJson.icon;
      this.parentActionID = actionnableJson.parentActionID;
      this.callback = actionnableJson.callback;
      this.options = actionnableJson.options;
      this.createdAt = actionnableJson.createdAt;
      this.updatedAt = actionnableJson.updatedAt;
      this.relationID = actionnableJson.relationID;
    } else {
      throw new Error(
        "Failed to create new instance of ${ Actionnable_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  name: string;
  description: string;
  icon: string;
  parentActionID?: number;
  callback: string;
  createdAt: string;
  updatedAt: string;
  relationID: number;
  options?: Array<Option>;
}
