import { Variation } from "../variation";

/**
 * Instrument_Entity type guard.
 *
 * @param {any} instrumentJson Instrument object from API
 * @returns {boolean} Return true if type is Instrument_Entity
 */
function validator(
  instrumentJson: unknown
): instrumentJson is Instrument_Entity {
  if (typeof instrumentJson === "object" && instrumentJson != null) {
    Instrument_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(instrumentJson).includes(element))
        console.log(`Field ${element} is null or undefined`);
    });
  }
  return true;
}

export default class Instrument_Entity {
  static requiredFields = ["variations"];

  constructor(instrumentJson: unknown) {
    if (validator(instrumentJson)) {
      this.id = instrumentJson.id;
      this.name = instrumentJson.name;
      this.icon = instrumentJson.icon;
      this.cartage = instrumentJson.cartage;
      this.shorthand = instrumentJson.shorthand;
      this.familyID = instrumentJson.familyID;
      this.relationID = instrumentJson.relationID;
      this.variations = instrumentJson.variations;
      this.variation = instrumentJson.variation;
    } else {
      throw new Error(
        "Failed to create new instance of ${ Instrument_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  name?: string;
  icon?: string;
  shorthand?: string;
  familyID?: number;
  cartage?: boolean;
  relationID?: number;
  variations: Array<Variation>;
  variation?: boolean;
}
