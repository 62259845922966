import { useMemo } from "react";
import { Family } from "../../entities/family";
import { useGetFamilyQuery, useGetFamiliesQuery } from "./familyEndpoints";

export function useFamily(
  familyID: number | undefined | null,
  opt?: { skip?: boolean }
) {
  const familyQuery = useGetFamilyQuery(familyID ?? 0, {
    skip: !familyID || opt?.skip,
  });
  const familyEntity = familyQuery.data;

  return useMemo(() => {
    const ret: typeof familyQuery & {
      family?: Family | undefined;
    } = {
      ...familyQuery,
    };
    if (familyEntity) ret.family = new Family(familyEntity);
    return ret;
  }, [familyEntity]);
}

export function useFamilies() {
  const familyQuery = useGetFamiliesQuery();
  const familyEntitiesMap = familyQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof familyQuery & {
      families?: Family[];
      familiesMap?: { [id: number]: Family };
    } = {
      ...familyQuery,
      families: [],
      familiesMap: {},
    };
    if (familyEntitiesMap) {
      const families: Family[] = [];
      const familiesMap: { [id: number]: Family } = {};

      for (const key in familyEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(familyEntitiesMap, key)) {
          const c = familyEntitiesMap[key];
          if (c) {
            const family = new Family(c);
            families.push(family);
            if (family?.id) familiesMap[family.id] = family;
          }
        }
      }
      families.sort((a, b) => a.pos - b.pos);
      ret.families = families;
      ret.familiesMap = familiesMap;
    }

    return ret;
  }, [familyEntitiesMap]);
}
