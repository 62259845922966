import EventStatus_Entity from "./eventStatus";
export default class EventStatus extends EventStatus_Entity {
  static fromList(eventStatussJSON: unknown): Array<EventStatus> {
    const eventStatuss: EventStatus[] = [];
    if (eventStatussJSON)
      Array.isArray(eventStatussJSON) &&
        eventStatussJSON.forEach((eventStatusJSON) => {
          eventStatuss.push(new EventStatus(eventStatusJSON));
        });
    return eventStatuss;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
