import { Box } from "@mui/material";
import {
  DataGridPremium,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-premium";
import { useFamilies } from "../../redux/family/familyHooks";
import { useInstruments } from "../../redux/instrument/instrumentHooks";
import { setFormOpen, setSelectedInstrumentID } from "../../reducers/rhapsody";
import { useDispatch } from "react-redux";
import Id from "../id/id";
import { Instrument_Entity } from "../../entities/instrument";

/**
 * Instrument
 *
 * @returns {null} Instrument
 */
export default function Instruments() {
  const { instruments } = useInstruments();
  const { familiesMap } = useFamilies();
  const dispatch = useDispatch();
  if (!familiesMap) return <Box />;

  const _instruments = instruments?.reduce<
    (Instrument_Entity & { familyName: string })[]
  >((a, v) => {
    a.push({ ...v, familyName: familiesMap[v.familyID ?? 0]?.name ?? "" });
    return a;
  }, []);

  return (
    <DataGridPremium
      rows={_instruments ?? []}
      density="compact"
      columns={[
        {
          field: "id",
          headerName: "ID",
          type: "number",
          width: 60,
          renderCell: (p) => {
            if (p.id === -1) return <Box />;
            return (
              <Id startDecorator={<i className="fa-solid fa-trumpet"></i>}>
                {p.id}
              </Id>
            );
          },
        },
        { field: "name", headerName: "Name", flex: 1 },
        { field: "shorthand", headerName: "Shorthand", flex: 1 },
        { field: "cartage", headerName: "Cartage", flex: 1, type: "boolean" },
        {
          field: "familyName",
          headerName: "Family",
          flex: 1,
        },
      ]}
      slots={{ toolbar: QuickSearchToolbar }}
      onRowClick={(e) => {
        dispatch(setSelectedInstrumentID(e.row.id));
        dispatch(setFormOpen({ isOpen: true, formID: "instrument" }));
      }}
    />
  );
}

function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
        display: "flex",
      }}
    >
      <GridToolbarQuickFilter
        variant="outlined"
        size="small"
        sx={{ flex: 1 }}
        fullWidth
      />
    </Box>
  );
}
