import { AccountBox, PowerSettingsNew } from "@mui/icons-material";
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { userSignout } from "@nerdjs/account-kit";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../hooks";
import { Button, IconButton } from "@mui/joy";
import { StyledMenu } from "@nerdjs/nerd-ui";

/**
 * @component
 * @returns {React.ReactElement} UserDisplay component
 */
export function NerdUserMenu(): React.ReactElement {
  const [anchorEl, setAnchorEl] = React.useState<
    (EventTarget & HTMLButtonElement) | null
  >(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const { t } = useTranslation();
  const open = Boolean(anchorEl);
  const id = open ? "basic-menu" : undefined;
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  /**
   * Disconnect user
   */
  function signout() {
    dispatch(userSignout());
  }

  return (
    <>
      {md ? (
        <Button
          sx={{ color: "white" }}
          size="sm"
          color="neutral"
          variant="plain"
          onClick={handleClick}
          startDecorator={
            <AccountBox sx={{ color: "white" }} fontSize="small" />
          }
        >
          {t("global.my_account")}
        </Button>
      ) : (
        <Tooltip title={`${t("entities:app.entity")}`}>
          <IconButton
            variant="plain"
            color="neutral"
            sx={{ color: "white" }}
            size="sm"
            onClick={handleClick}
          >
            <i className="fa-solid fa-user"></i>
          </IconButton>
        </Tooltip>
      )}
      <StyledMenu
        id={id}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          horizontal: "left",
          vertical: "top",
        }}
      >
        <MenuList>
          <MenuItem onClick={signout}>
            <ListItemIcon>
              <PowerSettingsNew />
            </ListItemIcon>
            <ListItemText>{t("global.signout")}</ListItemText>
          </MenuItem>
        </MenuList>
      </StyledMenu>
    </>
  );
}
