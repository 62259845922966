import { Box } from "@mui/material";
import {
  DataGridPremium,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-premium";
import { useDispatch } from "react-redux";
import { setFormOpen, setSelectedFamilyID } from "../../reducers/rhapsody";
import { useFamilies } from "../../redux/family/familyHooks";
import Id from "../id/id";

/**
 * Family
 *
 * @returns {null} Family
 */
export default function Families() {
  const { families } = useFamilies();
  const dispatch = useDispatch();

  return (
    <DataGridPremium
      rows={families ?? []}
      density="compact"
      columns={[
        {
          field: "id",
          headerName: "ID",
          type: "number",
          width: 60,
          renderCell: (p) => {
            if (p.id === -1) return <Box />;
            return (
              <Id
                startDecorator={<i className="fa-regular fa-layer-group"></i>}
              >
                {p.id}
              </Id>
            );
          },
        },
        { field: "name", headerName: "Name", flex: 1 },
        { field: "pos", headerName: "Position" },
      ]}
      slots={{ toolbar: QuickSearchToolbar }}
      onRowClick={(e) => {
        dispatch(setSelectedFamilyID(e.row.id));
        dispatch(setFormOpen({ isOpen: true, formID: "family" }));
      }}
    />
  );
}

function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
        display: "flex",
      }}
    >
      <GridToolbarQuickFilter
        variant="outlined"
        size="small"
        sx={{ flex: 1 }}
        fullWidth
      />
    </Box>
  );
}
