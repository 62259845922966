import { useMemo } from "react";
import { useGetActionQuery, useGetActionsQuery } from "./actionEndpoints";
import { Actionnable } from "../../entities/actionnable";

export function useAction(
  actionID: number | undefined | null,
  opt?: { skip?: boolean }
) {
  const actionQuery = useGetActionQuery(actionID ?? 0, {
    skip: !actionID || opt?.skip,
  });
  const actionEntity = actionQuery.data;

  return useMemo(() => {
    const ret: typeof actionQuery & {
      action?: Actionnable | undefined;
    } = {
      ...actionQuery,
    };
    if (actionEntity) ret.action = new Actionnable(actionEntity);
    return ret;
  }, [actionEntity]);
}

export function useActions() {
  const actionQuery = useGetActionsQuery();
  const actionEntitiesMap = actionQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof actionQuery & {
      actions?: Actionnable[];
      actionsMap?: { [id: number]: Actionnable };
    } = {
      ...actionQuery,
      actions: [],
      actionsMap: {},
    };
    if (actionEntitiesMap) {
      const actions: Actionnable[] = [];
      const actionsMap: { [id: number]: Actionnable } = {};

      for (const key in actionEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(actionEntitiesMap, key)) {
          const c = actionEntitiesMap[key];
          if (c) {
            const action = new Actionnable(c);
            actions.push(action);
            if (action?.id) actionsMap[action.id] = action;
          }
        }
      }
      ret.actions = actions;
      ret.actionsMap = actionsMap;
    }

    return ret;
  }, [actionEntitiesMap]);
}
