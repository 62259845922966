import { Chip, Tooltip } from "@mui/joy";
import { Box } from "@mui/material";
import {
  useGetCurrentUserQuery,
  useStopImpersonateMutation,
} from "@nerdjs/account-kit";

/**
 *
 * @returns {ReactElement} NerdImpersonate page
 */
export function NerdImpersonate() {
  const { data: currentUser } = useGetCurrentUserQuery();
  const [stopImpersonate] = useStopImpersonateMutation();

  if (currentUser?.impersonate)
    return (
      <Tooltip
        arrow
        size="sm"
        title={
          <Box sx={{ textAlign: "center" }}>
            You are impersonating{" "}
            <b>{`${currentUser?.firstName} ${currentUser?.lastName}`}</b>
            .<br />
            Click to stop.
          </Box>
        }
      >
        <Chip
          onClick={() => {
            stopImpersonate();
          }}
          endDecorator={<i className="fa-solid fa-xmark"></i>}
          startDecorator={<i className="fa-solid fa-eye"></i>}
          color="danger"
          size="sm"
        >
          Stop Impersonate
        </Chip>
      </Tooltip>
    );

  return <></>;
}
