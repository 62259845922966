import { Box } from "@mui/material";
import {
  DataGridPremium,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-premium";
import { useDispatch } from "react-redux";
import {
  setFormOpen,
  setSelectedConfigurationID,
} from "../../reducers/rhapsody";
import Id from "../id/id";
import { useConfigurations } from "../../redux/configuration/configurationHooks";

/**
 * Configuration
 *
 * @returns {null} Configuration
 */
export default function Configurations() {
  const { configurations } = useConfigurations();

  const dispatch = useDispatch();

  return (
    <DataGridPremium
      rows={configurations ?? []}
      density="compact"
      columns={[
        {
          field: "id",
          headerName: "ID",
          type: "number",
          width: 60,
          renderCell: (p) => {
            if (p.id === -1) return <Box />;
            return (
              <Id startDecorator={<i className="fa-solid fa-trumpet"></i>}>
                {p.id}
              </Id>
            );
          },
        },
        { field: "name", headerName: "Name", flex: 1 },
      ]}
      slots={{ toolbar: QuickSearchToolbar }}
      onRowClick={(e) => {
        dispatch(setSelectedConfigurationID(e.row.id));
        dispatch(setFormOpen({ isOpen: true, formID: "configuration" }));
      }}
    />
  );
}

function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
        display: "flex",
      }}
    >
      <GridToolbarQuickFilter
        variant="outlined"
        size="small"
        sx={{ flex: 1 }}
        fullWidth
      />
    </Box>
  );
}
