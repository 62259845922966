import { Box } from "@mui/material";
import {
  DataGridPremium,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-premium";
import { useGetOrganizationsQuery } from "@nerdjs/account-kit";
import { useDispatch } from "react-redux";
import { setFormOpen, setSelectedNoteTypeID } from "../../reducers/rhapsody";
import { useNoteTypes } from "../../redux/noteType/noteTypeHooks";
import Id from "../id/id";
import { noteTypeData } from "../noteTypeForm/noteTypeForm";

/**
 * NoteType
 *
 * @returns {null} NoteType
 */
export default function NoteTypes() {
  const { noteTypes } = useNoteTypes();
  const { data } = useGetOrganizationsQuery();
  const dispatch = useDispatch();

  return (
    <DataGridPremium
      rows={noteTypes ?? []}
      density="compact"
      columns={[
        {
          field: "id",
          headerName: "ID",
          type: "number",
          width: 60,
          renderCell: (p) => {
            if (p.id === -1) return <Box />;
            return (
              <Id startDecorator={<i className="fa-solid fa-trumpet"></i>}>
                {p.id}
              </Id>
            );
          },
        },
        { field: "name", headerName: "Name", width: 150 },
        {
          field: "kind",
          headerName: "Type",
          flex: 1,
          valueGetter: (p) =>
            noteTypeData.find((e) => e.value === p.value)?.label,
        },
        {
          field: "organizationID",
          width: 200,
          headerName: "Restricted to organization",
          valueGetter: (p) =>
            p.row.organizationID
              ? data?.entities[p.row.organizationID]?.name
              : "",
        },
      ]}
      slots={{ toolbar: QuickSearchToolbar }}
      onRowClick={(e) => {
        dispatch(setSelectedNoteTypeID(e.row.id));
        dispatch(setFormOpen({ isOpen: true, formID: "noteType" }));
      }}
    />
  );
}

function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
        display: "flex",
      }}
    >
      <GridToolbarQuickFilter
        variant="outlined"
        size="small"
        sx={{ flex: 1 }}
        fullWidth
      />
    </Box>
  );
}
