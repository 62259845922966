import { Step } from "../step";

/**
 * Event_Entity type guard.
 *
 * @param {any} eventJson Event object from API
 * @returns {boolean} Return true if type is Event_Entity
 */
function validator(eventJson: unknown): eventJson is Event_Entity {
  if (typeof eventJson === "object" && eventJson != null) {
    Event_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(eventJson).includes(element))
        throw new Error("Field ${element} is null or undefined");
    });
  }
  return true;
}

export default class Event_Entity {
  static requiredFields = [
    "id",
    "configurationID",
    "hookID",
    "scope",
    "description",
    "itemID",
    "startingAt",
    "createdBy",
    "cancelled",
    "cancelledAt",
    "cancelledBy",
    "completed",
    "completedAt",
    "notify",
    "steps",
    "createdAt",
    "updatedAt",
  ];

  constructor(eventJson: unknown) {
    if (validator(eventJson)) {
      this.id = eventJson.id;
      this.configurationID = eventJson.configurationID;
      this.hookID = eventJson.hookID;
      this.scope = eventJson.scope;
      this.description = eventJson.description;
      this.itemID = eventJson.itemID;
      this.startingAt = eventJson.startingAt;
      this.createdBy = eventJson.createdBy;
      this.cancelled = eventJson.cancelled;
      this.cancelledAt = eventJson.cancelledAt;
      this.cancelledBy = eventJson.cancelledBy;
      this.completed = eventJson.completed;
      this.completedAt = eventJson.completedAt;
      this.notify = eventJson.notify;
      this.steps = eventJson.steps;
      this.createdAt = eventJson.createdAt;
      this.updatedAt = eventJson.updatedAt;
    } else {
      throw new Error(
        "Failed to create new instance of ${ Event_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  configurationID: number;
  hookID: number;
  scope: string;
  description: string;
  itemID: number;
  startingAt: string;
  createdBy: string;
  cancelled: boolean;
  cancelledAt: string;
  cancelledBy: string;
  completed: boolean;
  completedAt: string;
  notify: boolean;
  steps: Array<Step>;
  createdAt: string;
  updatedAt: string;
}
