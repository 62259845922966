import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { EventStatus_Entity } from "../../entities/eventStatus";
import { rhapsodyApi } from "../api/rhapsodyApi";

const eventStatusesAdapter = createEntityAdapter<EventStatus_Entity>();
const initialState = eventStatusesAdapter.getInitialState();

export const eventStatusEndpoints = rhapsodyApi.injectEndpoints({
  endpoints: (build) => ({
    getEventStatuses: build.query<EntityState<EventStatus_Entity>, void>({
      query: () => `eventStatuses`,
      transformResponse: (responseData: EventStatus_Entity[]) => {
        return eventStatusesAdapter.setAll(initialState, responseData);
      },
      providesTags: ["eventStatuses"],
    }),
    getEventStatus: build.query<EventStatus_Entity, number>({
      query: (id) => `eventStatuses/${id}`,
      providesTags: (result, error, id) => [{ type: "eventStatuses", id }],
    }),
    createEventStatus: build.mutation<
      EventStatus_Entity,
      Partial<EventStatus_Entity>
    >({
      query: (body) => ({
        method: "POST",
        body,
        url: `eventStatuses`,
      }),
      invalidatesTags: ["eventStatuses"],
    }),
    updateEventStatus: build.mutation<
      void,
      { id: number; body: Partial<EventStatus_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `eventStatuses/${args.id}`,
      }),
      invalidatesTags: ["eventStatuses"],
    }),
    deleteEventStatus: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `eventStatuses/${id}`,
      }),
      invalidatesTags: ["eventStatuses"],
    }),
  }),
});

export const {
  useGetEventStatusQuery,
  useGetEventStatusesQuery,
  useCreateEventStatusMutation,
  useDeleteEventStatusMutation,
  useUpdateEventStatusMutation,
} = eventStatusEndpoints;
