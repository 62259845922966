import { Edge, Node, Position } from "reactflow";
import Pipeline_Entity from "./pipeline";
export default class Pipeline extends Pipeline_Entity {
  static fromList(pipelinesJSON: unknown): Array<Pipeline> {
    const pipelines: Pipeline[] = [];
    if (pipelinesJSON)
      Array.isArray(pipelinesJSON) &&
        pipelinesJSON.forEach((pipelineJSON) => {
          pipelines.push(new Pipeline(pipelineJSON));
        });
    return pipelines;
  }

  getNodes(): Node[] {
    return this?.stages.reduce<Node[]>((a, v) => {
      a.push({
        id: `${v.id}`,
        style: { backgroundColor: v.color, color: "white", borderRadius: 14 },
        sourcePosition: Position.Left,
        targetPosition: Position.Right,
        draggable: true,
        selectable: true,
        focusable: true,
        connectable: false,
        deletable: false,
        type: "stage",
        position: {
          x: parseInt(v.position?.split(",")[0] ?? "0"),
          y: parseInt(v.position?.split(",")[1] ?? "0"),
        },
        data: { label: v.name, stage: v },
      });
      return a;
    }, []);
  }

  getEdges(): Edge[] {
    let edges = this?.stages.reduce<Edge[]>((a, v) => {
      const available = v.available?.split(",").filter((e) => e);
      const excluded = v.excluded?.split(",").filter((e) => e);
      available?.forEach((av) => {
        if (av === "*") {
          this.stages.forEach((s) => {
            if (!excluded?.includes(`${s.id}`) && `${s.id}` !== `${v.id}`)
              a.push({
                id: `${s.id}-${v.id}`,
                source: `${s.id}`,
                target: `${v.id}`,
                animated: true,
                deletable: false,
              });
          });
        } else {
          a.push({
            id: `${av}-${v.id}`,
            source: `${av}`,
            target: `${v.id}`,
            animated: true,
            deletable: false,
          });
        }
      });
      v.interactors?.forEach((i) => {
        if (i.segueID) {
          a.push({
            id: `${v.id}-${i.id}`,
            source: `${v.id}`,
            target: `${i.segueID}`,
            animated: true,
          });
        }
      });
      return a;
    }, []);

    edges = edges.reduce<Edge[]>((a, v) => {
      if (a.findIndex((e) => e.id === v.id) === -1) a.push(v);
      return a;
    }, []);

    return edges;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
