import {
  Button,
  ButtonGroup,
  Input,
  Option,
  Select,
  Textarea,
  Typography,
} from "@mui/joy";
import { Box, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NoteType_Entity } from "../../entities/noteType";
import {
  formOpenSelector,
  selectedNoteTypeIDSelector,
  setFormOpen,
  setSelectedNoteTypeID,
} from "../../reducers/rhapsody";
import {
  useCreateNoteTypeMutation,
  useDeleteNoteTypeMutation,
  useUpdateNoteTypeMutation,
} from "../../redux/noteType/noteTypeEndpoints";
import { useNoteType } from "../../redux/noteType/noteTypeHooks";
import DialogClose from "../dialogClose/dialogClose";
import { useGetOrganizationsQuery } from "@nerdjs/account-kit";

/**
 *
 * @returns {ReactElement} NoteTypeForm page
 */
export function NoteTypeForm() {
  const open = useSelector(formOpenSelector("noteType"));
  const dispatch = useDispatch();
  const selectedNoteTypeID = useSelector(selectedNoteTypeIDSelector);
  const { noteType } = useNoteType(selectedNoteTypeID);
  const [createNoteType, { isLoading: l1 }] = useCreateNoteTypeMutation();
  const [updateNoteType, { isLoading: l2 }] = useUpdateNoteTypeMutation();
  const [deleteNoteType] = useDeleteNoteTypeMutation();
  const [_noteType, setNoteType] = useState<Partial<NoteType_Entity>>({
    name: "",
  });
  const { data: orgData } = useGetOrganizationsQuery();

  useEffect(() => {
    if (noteType?.id) setNoteType(noteType);
  }, [noteType]);

  const close = () => {
    dispatch(setFormOpen({ isOpen: false, formID: "noteType" }));
    dispatch(setSelectedNoteTypeID(null));
  };

  const save = () => {
    if (_noteType.id) {
      updateNoteType({ id: _noteType.id, body: _noteType });
    } else {
      createNoteType(_noteType);
    }
    close();
  };

  return (
    <DialogClose open={open} onClose={close} maxWidth="lg">
      <DialogTitle>
        <Typography
          level="title-lg"
          startDecorator={<i className="fa-regular fa-layer-group"></i>}
        >
          {_noteType?.name ? _noteType?.name : "New NoteType"}
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ display: "flex", gap: 1, flexDirection: "column" }}>
        <Input
          value={_noteType.name}
          onChange={(e) => setNoteType((p) => ({ ...p, name: e.target.value }))}
          autoFocus
          startDecorator="Name:"
          sx={{ boxShadow: "none" }}
        />
        <Select
          slotProps={{
            listbox: { sx: { zIndex: 9999 } },
          }}
          value={_noteType.organizationID}
          onChange={(e, v) =>
            setNoteType((p) => ({ ...p, organizationID: v as number }))
          }
          startDecorator="Restrict to Organization:"
          sx={{ boxShadow: "none" }}
        >
          {orgData?.ids?.map((id) => {
            return (
              <Option
                key={orgData.entities[id]?.id}
                value={orgData.entities[id]?.id}
              >
                {orgData.entities[id]?.name}
              </Option>
            );
          })}
        </Select>
        <Typography>Type:</Typography>
        <ButtonGroup>
          {noteTypeData.map((d) => (
            <Button
              variant={_noteType.kind === d.value ? "solid" : undefined}
              key={d.value}
              size="sm"
              startDecorator={<i className={d.icon} />}
              onClick={() => setNoteType((p) => ({ ...p, kind: d.value }))}
            >
              {d.label}
            </Button>
          ))}
        </ButtonGroup>
        {_noteType.kind === "select" ? (
          <>
            <Typography>Select Options:</Typography>
            <Textarea
              sx={{ boxShadow: "none" }}
              value={_noteType.data}
              onChange={(e) =>
                setNoteType((p) => ({ ...p, data: e.target.value }))
              }
              placeholder={`Option A, Option B`}
              endDecorator={
                <Typography level="body-xs">
                  Seperate your option with a comma.
                </Typography>
              }
            />
          </>
        ) : (
          []
        )}
      </DialogContent>
      <DialogActions>
        {selectedNoteTypeID ? (
          <Button
            color="danger"
            variant="soft"
            onClick={() => {
              deleteNoteType(selectedNoteTypeID);
              close();
            }}
          >
            Delete
          </Button>
        ) : (
          []
        )}
        <Box sx={{ flexGrow: 1 }} />
        <Button variant="soft" color="neutral" onClick={close}>
          Close
        </Button>
        <Button loading={l1 || l2} onClick={save} disabled={!_noteType.name}>
          {selectedNoteTypeID ? "Save" : "Create"}
        </Button>
      </DialogActions>
    </DialogClose>
  );
}

export const noteTypeData = [
  {
    value: "textfield",
    label: "Text",
    icon: "fa-regular fa-i-cursor",
  },
  {
    value: "phone",
    label: "Phone",
    icon: "fa-duotone fa-phone",
  },
  {
    value: "date",
    label: "Date",
    icon: "fa-duotone fa-calendar-days",
  },
  {
    value: "time",
    label: "Time",
    icon: "fa-duotone fa-clock",
  },
  {
    value: "dateRange",
    label: "Date Range",
    icon: "fa-duotone fa-calendar-range",
  },
  {
    value: "color",
    label: "Color",
    icon: "fa-duotone fa-palette",
  },
  {
    value: "boolean",
    label: "Checkbox",
    icon: "fa-duotone fa-square-check",
  },
  {
    value: "rating",
    label: "Star Rating",
    icon: "fa-duotone fa-star",
  },
  {
    value: "select",
    label: "Select",
    icon: "fa-solid fa-rectangle-history",
  },
];
