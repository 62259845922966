import Hook_Entity from "./hook";
export default class Hook extends Hook_Entity {
  static fromList(hooksJSON: unknown): Array<Hook> {
    const hooks: Hook[] = [];
    if (hooksJSON)
      Array.isArray(hooksJSON) &&
        hooksJSON.forEach((hookJSON) => {
          hooks.push(new Hook(hookJSON));
        });
    return hooks;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
