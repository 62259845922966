/**
 * Family_Entity type guard.
 *
 * @param {any} familyJson Family object from API
 * @returns {boolean} Return true if type is Family_Entity
 */
function validator(familyJson: unknown): familyJson is Family_Entity {
  if (typeof familyJson === "object" && familyJson != null) {
    Family_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(familyJson).includes(element))
        throw new Error("Field ${element} is null or undefined");
    });
  }
  return true;
}

export default class Family_Entity {
  static requiredFields = [];

  constructor(familyJson: unknown) {
    if (validator(familyJson)) {
      this.id = familyJson.id;
      this.name = familyJson.name;
      this.icon = familyJson.icon;
      this.pos = familyJson.pos;
      this.groupID = familyJson.groupID;
    } else {
      throw new Error(
        "Failed to create new instance of ${ Family_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  name?: string;
  icon?: string;
  pos: number;
  groupID?: number;
}
