import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import DialogClose from "../dialogClose/dialogClose";
import { useSelector } from "react-redux";
import { formOpenSelector, setFormOpen } from "../../reducers/rhapsody";
import { useDispatch } from "react-redux";
import { Button, Input, Typography } from "@mui/joy";
import { useState } from "react";
import { Pipeline_Entity } from "../../entities/pipeline";
import { useCreatePipelineMutation } from "../../redux/pipeline/pipelineEndpoints";
import { useNavigate } from "react-router-dom";
import { RouterConfig } from "../config/routerConfig";

/**
 *
 * @returns {ReactElement} PipelineForm page
 */
export function PipelineForm() {
  const open = useSelector(formOpenSelector("pipeline"));
  const dispatch = useDispatch();
  const [pipeline, setPipeline] = useState<Partial<Pipeline_Entity>>({
    name: "",
  });
  const navigate = useNavigate();
  const [createPipeline, { isLoading }] = useCreatePipelineMutation();

  const close = () =>
    dispatch(setFormOpen({ isOpen: false, formID: "pipeline" }));

  const create = () => {
    createPipeline(pipeline)
      .unwrap()
      .then((e) => {
        if (e.id) {
          navigate(`${RouterConfig.pipelines}/${e.id}`);
          close();
        }
      });
  };

  return (
    <DialogClose open={open} onClose={close}>
      <DialogTitle>
        <Typography
          level="title-lg"
          startDecorator={<i className="fa-regular fa-arrow-progress"></i>}
        >
          New Pipeline
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Input
          value={pipeline.name}
          onChange={(e) => setPipeline((p) => ({ ...p, name: e.target.value }))}
          autoFocus
          startDecorator="Name:"
          sx={{ boxShadow: "none" }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="soft" color="neutral" onClick={close}>
          Close
        </Button>
        <Button loading={isLoading} onClick={create} disabled={!pipeline.name}>
          Create
        </Button>
      </DialogActions>
    </DialogClose>
  );
}
