import { useMemo } from "react";
import { useGetStageQuery, useGetStagesQuery } from "./stageEndpoints";
import { Stage } from "../../entities/stage";
import { Dictionary } from "@reduxjs/toolkit";

export function useStage(
  stageID: number | undefined | null,
  opt?: { skip?: boolean }
) {
  const stageQuery = useGetStageQuery(stageID ?? 0, {
    skip: !stageID || opt?.skip,
  });
  const stageEntity = stageQuery.data;

  return useMemo(() => {
    const ret: typeof stageQuery & { stage?: Stage | undefined } = {
      ...stageQuery,
    };
    if (stageEntity) ret.stage = new Stage(stageEntity);
    return ret;
  }, [stageEntity]);
}

export function useStages(args?: any) {
  const stageQuery = useGetStagesQuery(args);
  const stageEntitiesMap = stageQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof stageQuery & {
      stages?: Stage[];
      stagesMap?: { [id: number]: Stage };
    } = {
      ...stageQuery,
      stages: [],
      stagesMap: {},
    };
    if (stageEntitiesMap) {
      const stages: Stage[] = [];
      const stagesMap: { [id: number]: Stage } = {};

      for (const key in stageEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(stageEntitiesMap, key)) {
          const c = stageEntitiesMap[key];
          if (c) {
            const stage = new Stage(c);
            stages.push(stage);
            if (stage?.id) stagesMap[stage.id] = stage;
          }
        }
      }
      ret.stages = stages;
      ret.stagesMap = stagesMap;
    }

    return ret;
  }, [stageEntitiesMap]);
}
