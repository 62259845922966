import Interactor_Entity from "./interactor";
export default class Interactor extends Interactor_Entity {
  static fromList(interactorsJSON: unknown): Array<Interactor> {
    const interactors: Interactor[] = [];
    if (interactorsJSON)
      Array.isArray(interactorsJSON) &&
        interactorsJSON.forEach((interactorJSON) => {
          interactors.push(new Interactor(interactorJSON));
        });
    return interactors;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
