import type { PayloadAction } from "@reduxjs/toolkit";
import { createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../redux/store";

interface RhapsodyState {
  selectedPipelineID?: number | null;
  selectedStageID?: number | null;
  selectedInstrumentID?: number | null;
  selectedFamilyID?: number | null;
  selectedSectionID?: number | null;
  selectedNoteTypeID?: number | null;
  selectedHookID?: number | null;
  selectedActionID?: number | null;
  selectedConfigurationID?: number | null;
  selectedEventID?: number | null;
  formOpen: { [key: string]: boolean };
}

const initialState = {
  formOpen: {},
} as RhapsodyState;

export const rhapsodySlice = createSlice({
  name: "rhapsody",
  initialState,
  reducers: {
    setSelectedPipelineID(state, action: PayloadAction<number | null>) {
      state.selectedPipelineID = action.payload;
    },
    setSelectedNoteTypeID(state, action: PayloadAction<number | null>) {
      state.selectedNoteTypeID = action.payload;
    },
    setSelectedStageID(state, action: PayloadAction<number | null>) {
      state.selectedStageID = action.payload;
    },
    setSelectedInstrumentID(state, action: PayloadAction<number | null>) {
      state.selectedInstrumentID = action.payload;
    },
    setSelectedFamilyID(state, action: PayloadAction<number | null>) {
      state.selectedFamilyID = action.payload;
    },
    setSelectedSectionID(state, action: PayloadAction<number | null>) {
      state.selectedSectionID = action.payload;
    },
    setSelectedHookID(state, action: PayloadAction<number | null>) {
      state.selectedHookID = action.payload;
    },
    setSelectedActionID(state, action: PayloadAction<number | null>) {
      state.selectedActionID = action.payload;
    },
    setSelectedEventID(state, action: PayloadAction<number | null>) {
      state.selectedEventID = action.payload;
    },
    setSelectedConfigurationID(state, action: PayloadAction<number | null>) {
      state.selectedConfigurationID = action.payload;
    },
    setFormOpen(
      state,
      action: PayloadAction<{ formID: FormID; isOpen: boolean }>
    ) {
      const { formID, isOpen } = action.payload;
      state.formOpen[formID] = isOpen;
    },
  },
});

export const {
  setSelectedNoteTypeID,
  setFormOpen,
  setSelectedPipelineID,
  setSelectedStageID,
  setSelectedInstrumentID,
  setSelectedActionID,
  setSelectedConfigurationID,
  setSelectedEventID,
  setSelectedFamilyID,
  setSelectedHookID,
  setSelectedSectionID,
} = rhapsodySlice.actions;

export type FormID =
  | "stage"
  | "pipeline"
  | "instrument"
  | "family"
  | "section"
  | "hook"
  | "action"
  | "event"
  | "testPipeline"
  | "noteType"
  | "configuration";

export const formOpenSelector = (formID: FormID) => {
  return createSelector([(s) => s.rhapsody.formOpen], (s) => {
    return s[formID] ?? false;
  });
};

export const selectedStageIDSelector = createSelector(
  (s: RootState) => s.rhapsody.selectedStageID,
  (s) => s
);

export const selectedNoteTypeIDSelector = createSelector(
  (s: RootState) => s.rhapsody.selectedNoteTypeID,
  (s) => s
);

export const selectedPipelineIDSelector = createSelector(
  (s: RootState) => s.rhapsody.selectedPipelineID,
  (s) => s
);

export const selectedInstrumentIDSelector = createSelector(
  (s: RootState) => s.rhapsody.selectedInstrumentID,
  (s) => {
    return s;
  }
);

export const selectedFamilyIDSelector = createSelector(
  (s: RootState) => s.rhapsody.selectedFamilyID,
  (s) => s
);
export const selectedSectionIDSelector = createSelector(
  (s: RootState) => s.rhapsody.selectedSectionID,
  (s) => s
);
export const selectedHookIDSelector = createSelector(
  (s: RootState) => s.rhapsody.selectedHookID,
  (s) => s
);
export const selectedActionIDSelector = createSelector(
  (s: RootState) => s.rhapsody.selectedActionID,
  (s) => s
);
export const selectedEventIDSelector = createSelector(
  (s: RootState) => s.rhapsody.selectedEventID,
  (s) => s
);
export const selectedConfigurationIDSelector = createSelector(
  (s: RootState) => s.rhapsody.selectedConfigurationID,
  (s) => s
);
