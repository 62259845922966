import SectionInstrument_Entity from "./sectionInstrument";
export default class SectionInstrument extends SectionInstrument_Entity {
  static fromList(sectionInstrumentsJSON: unknown): Array<SectionInstrument> {
    const sectionInstruments: SectionInstrument[] = [];
    if (sectionInstrumentsJSON)
      Array.isArray(sectionInstrumentsJSON) &&
        sectionInstrumentsJSON.forEach((sectionInstrumentJSON) => {
          sectionInstruments.push(new SectionInstrument(sectionInstrumentJSON));
        });
    return sectionInstruments;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
