import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { Family_Entity } from "../../entities/family";
import { museApi } from "../api/museApi";

const familiesAdapter = createEntityAdapter<Family_Entity>();
const initialState = familiesAdapter.getInitialState();

export const familyEndpoints = museApi.injectEndpoints({
  endpoints: (build) => ({
    getFamilies: build.query<EntityState<Family_Entity>, void>({
      query: () => `families`,
      transformResponse: (responseData: Family_Entity[]) => {
        return familiesAdapter.setAll(initialState, responseData);
      },
      providesTags: ["families"],
    }),
    getFamily: build.query<Family_Entity, number>({
      query: (id) => `families/${id}`,
      providesTags: (result, error, id) => [{ type: "families", id }],
    }),
    createFamily: build.mutation<Family_Entity, Partial<Family_Entity>>({
      query: (body) => ({
        method: "POST",
        body,
        url: `families`,
      }),
      invalidatesTags: ["families"],
    }),
    updateFamily: build.mutation<
      void,
      { id: number; body: Partial<Family_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `families/${args.id}`,
      }),
      invalidatesTags: ["families"],
    }),
    deleteFamily: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `families/${id}`,
      }),
      invalidatesTags: ["families"],
    }),
  }),
});

export const {
  useGetFamilyQuery,
  useGetFamiliesQuery,
  useCreateFamilyMutation,
  useDeleteFamilyMutation,
  useUpdateFamilyMutation,
} = familyEndpoints;
