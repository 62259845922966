import { useMemo } from "react";
import { Interactor } from "../../entities/interactor";
import {
  useGetInteractorQuery,
  useGetInteractorsQuery,
} from "./interactorEndpoints";

export function useInteractor(
  interactorID: number | undefined | null,
  opt?: { skip?: boolean }
) {
  const interactorQuery = useGetInteractorQuery(interactorID ?? 0, {
    skip: !interactorID || opt?.skip,
  });
  const interactorEntity = interactorQuery.data;

  return useMemo(() => {
    const ret: typeof interactorQuery & {
      interactor?: Interactor | undefined;
    } = {
      ...interactorQuery,
    };
    if (interactorEntity) ret.interactor = new Interactor(interactorEntity);
    return ret;
  }, [interactorEntity]);
}

export function useInteractors() {
  const interactorQuery = useGetInteractorsQuery();
  const interactorEntitiesMap = interactorQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof interactorQuery & {
      interactors?: Interactor[];
      interactorsMap?: { [id: number]: Interactor };
    } = {
      ...interactorQuery,
      interactors: [],
      interactorsMap: {},
    };
    if (interactorEntitiesMap) {
      const interactors: Interactor[] = [];
      const interactorsMap: { [id: number]: Interactor } = {};

      for (const key in interactorEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(interactorEntitiesMap, key)) {
          const c = interactorEntitiesMap[key];
          if (c) {
            const interactor = new Interactor(c);
            interactors.push(interactor);
            if (interactor?.id) interactorsMap[interactor.id] = interactor;
          }
        }
      }
      ret.interactors = interactors;
      ret.interactorsMap = interactorsMap;
    }

    return ret;
  }, [interactorEntitiesMap]);
}
