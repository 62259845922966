import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { Event_Entity } from "../../entities/event";
import { autopilotApi } from "../api/autopilotApi";

const eventsAdapter = createEntityAdapter<Event_Entity>();
const initialState = eventsAdapter.getInitialState();

export const eventEndpoints = autopilotApi.injectEndpoints({
  endpoints: (build) => ({
    getEvents: build.query<EntityState<Event_Entity>, void>({
      query: () => `events`,
      transformResponse: (responseData: Event_Entity[]) => {
        return eventsAdapter.setAll(initialState, responseData);
      },
      providesTags: ["events"],
    }),
    getEvent: build.query<Event_Entity, number>({
      query: (id) => `events/${id}`,
      providesTags: (result, error, id) => [{ type: "events", id }],
    }),
    createEvent: build.mutation<Event_Entity, Partial<Event_Entity>>({
      query: (body) => ({
        method: "POST",
        body,
        url: `events`,
      }),
      invalidatesTags: ["events"],
    }),
    updateEvent: build.mutation<
      void,
      { id: number; body: Partial<Event_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `events/${args.id}`,
      }),
      invalidatesTags: ["events"],
    }),
    deleteEvent: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `events/${id}`,
      }),
      invalidatesTags: ["events"],
    }),
  }),
});

export const {
  useGetEventQuery,
  useGetEventsQuery,
  useCreateEventMutation,
  useDeleteEventMutation,
  useUpdateEventMutation,
} = eventEndpoints;
