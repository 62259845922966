import {
  Button,
  IconButton,
  Input,
  Option,
  Select,
  Typography,
} from "@mui/joy";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Section_Entity } from "../../entities/section";
import {
  formOpenSelector,
  selectedSectionIDSelector,
  setFormOpen,
  setSelectedFamilyID,
  setSelectedSectionID,
} from "../../reducers/rhapsody";
import {
  useCreateSectionMutation,
  useUpdateSectionMutation,
} from "../../redux/section/sectionEndpoints";
import { useSection } from "../../redux/section/sectionHooks";
import DialogClose from "../dialogClose/dialogClose";
import { useFamilies } from "../../redux/family/familyHooks";
import { useInstruments } from "../../redux/instrument/instrumentHooks";

/**
 *
 * @returns {ReactElement} SectionForm page
 */
export function SectionForm() {
  const open = useSelector(formOpenSelector("section"));
  const dispatch = useDispatch();
  const { families } = useFamilies();
  const { instruments } = useInstruments();
  const selectedSectionID = useSelector(selectedSectionIDSelector);
  const { section } = useSection(selectedSectionID);
  const [createSection, { isLoading: l1 }] = useCreateSectionMutation();
  const [updateSection, { isLoading: l2 }] = useUpdateSectionMutation();
  const [_section, setSection] = useState<Partial<Section_Entity>>({
    name: "",
  });

  useEffect(() => {
    if (section?.id) setSection(section);
  }, [section]);

  const close = () => {
    dispatch(setFormOpen({ isOpen: false, formID: "section" }));
    dispatch(setSelectedSectionID(null));
  };

  const save = () => {
    if (_section.id) {
      updateSection({ id: _section.id, body: _section });
    } else {
      createSection(_section);
    }
    close();
  };

  return (
    <DialogClose open={open} onClose={close}>
      <DialogTitle>
        <Typography
          level="title-lg"
          startDecorator={<i className="fa-solid fa-users-line"></i>}
        >
          {_section?.name ? _section?.name : "New Section"}
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ display: "flex", gap: 1, flexDirection: "column" }}>
        <Input
          value={_section.name}
          onChange={(e) => setSection((p) => ({ ...p, name: e.target.value }))}
          autoFocus
          startDecorator="Name:"
          sx={{ boxShadow: "none" }}
        />
        <Input
          value={_section.shorthand}
          onChange={(e) =>
            setSection((p) => ({ ...p, shorthand: e.target.value }))
          }
          autoFocus
          startDecorator="Shorthand:"
          sx={{ boxShadow: "none" }}
        />
        <Select
          slotProps={{
            listbox: { sx: { zIndex: 9999 } },
          }}
          value={_section.defaultInstrumentID}
          onChange={(e, v) =>
            setSection((p) => ({ ...p, defaultInstrumentID: v as number }))
          }
          startDecorator="Default Instrument:"
          sx={{ boxShadow: "none" }}
        >
          {instruments?.map((i) => (
            <Option key={i.id} value={i.id}>
              {i.name}
            </Option>
          ))}
        </Select>
        <Select
          slotProps={{
            listbox: { sx: { zIndex: 9999 } },
          }}
          value={_section.familyID}
          onChange={(e, v) =>
            setSection((p) => ({ ...p, familyID: v as number }))
          }
          startDecorator="Family:"
          sx={{ boxShadow: "none" }}
          endDecorator={
            _section.familyID ? (
              <IconButton
                onClick={() => {
                  dispatch(setSelectedFamilyID(_section.familyID ?? 0));
                  dispatch(setFormOpen({ isOpen: true, formID: "family" }));
                }}
              >
                <i className="fa-solid fa-arrow-up-right-from-square"></i>
              </IconButton>
            ) : (
              []
            )
          }
        >
          {families?.map((f) => (
            <Option key={f.id} value={f.id}>
              {f.name}
            </Option>
          ))}
        </Select>
        <Input
          value={_section.pos}
          onChange={(e) =>
            setSection((p) => ({ ...p, pos: parseInt(e.target.value) }))
          }
          type="number"
          startDecorator="Order in Family:"
          sx={{ boxShadow: "none" }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="soft" color="neutral" onClick={close}>
          Close
        </Button>
        <Button loading={l1 || l2} onClick={save} disabled={!_section.name}>
          {selectedSectionID ? "Save" : "Create"}
        </Button>
      </DialogActions>
    </DialogClose>
  );
}
