import { useMemo } from "react";
import { Section } from "../../entities/section";
import { useGetSectionQuery, useGetSectionsQuery } from "./sectionEndpoints";

export function useSection(
  sectionID: number | undefined | null,
  opt?: { skip?: boolean }
) {
  const sectionQuery = useGetSectionQuery(sectionID ?? 0, {
    skip: !sectionID || opt?.skip,
  });
  const sectionEntity = sectionQuery.data;

  return useMemo(() => {
    const ret: typeof sectionQuery & {
      section?: Section | undefined;
    } = {
      ...sectionQuery,
    };
    if (sectionEntity) ret.section = new Section(sectionEntity);
    return ret;
  }, [sectionEntity]);
}

export function useSections() {
  const sectionQuery = useGetSectionsQuery();
  const sectionEntitiesMap = sectionQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof sectionQuery & {
      sections?: Section[];
      sectionsMap?: { [id: number]: Section };
    } = {
      ...sectionQuery,
      sections: [],
      sectionsMap: {},
    };
    if (sectionEntitiesMap) {
      const sections: Section[] = [];
      const sectionsMap: { [id: number]: Section } = {};

      for (const key in sectionEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(sectionEntitiesMap, key)) {
          const c = sectionEntitiesMap[key];
          if (c) {
            const section = new Section(c);
            sections.push(section);
            if (section?.id) sectionsMap[section.id] = section;
          }
        }
      }
      ret.sections = sections;
      ret.sectionsMap = sectionsMap;
    }

    return ret;
  }, [sectionEntitiesMap]);
}
