/**
 * Section_Entity type guard.
 *
 * @param {any} sectionJson Section object from API
 * @returns {boolean} Return true if type is Section_Entity
 */
function validator(sectionJson: unknown): sectionJson is Section_Entity {
  if (typeof sectionJson === "object" && sectionJson != null) {
    Section_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(sectionJson).includes(element))
        throw new Error("Field ${element} is null or undefined");
    });
  }
  return true;
}

export default class Section_Entity {
  static requiredFields = [];

  constructor(sectionJson: unknown) {
    if (validator(sectionJson)) {
      this.id = sectionJson.id;
      this.shorthand = sectionJson.shorthand;
      this.defaultInstrumentID = sectionJson.defaultInstrumentID;
      this.name = sectionJson.name;
      this.pos = sectionJson.pos;
      this.familyID = sectionJson.familyID;
      this.familyName = sectionJson.familyName;
      this.familyPos = sectionJson.familyPos;
    } else {
      throw new Error(
        "Failed to create new instance of ${ Section_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  name?: string;
  shorthand?: string;
  defaultInstrumentID?: number;
  pos?: number;
  familyID?: number;
  familyName?: string;
  familyPos?: number;
}
