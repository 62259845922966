import React, { ReactElement } from "react";
import { Box, Tooltip } from "@mui/material";

export default function Id({
  children,
  startDecorator,
  tooltip,
  noStyle,
}: {
  children?: ReactElement | string | number;
  startDecorator?: ReactElement | null | undefined;
  tooltip?: string;
  noStyle?: boolean;
}) {
  const [click, setClick] = React.useState(false);

  return (
    <Tooltip title={click ? "Copied!" : tooltip ?? "Click to Copy"}>
      <Box
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setClick(true);
          copyTextToClipboard(children as string);
        }}
        sx={
          !noStyle
            ? {
                fontSize: 10,
                border: "solid 1px #757575",
                color: "#757575",
                background: "rgba(155,155,155,0.05)",
                borderRadius: "4px",
                cursor: "pointer",
                height: 16,
                overflow: "hidden",
                display: "flex",
                alignItems: "center",
                position: "relative",
                flexShrink: 0,
              }
            : { display: "inline-block" }
        }
      >
        {startDecorator ? (
          <Box
            sx={{
              fontSize: 9,
              color: "white",
              background: "#757575",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              p: "2px",
            }}
          >
            {startDecorator}
          </Box>
        ) : (
          []
        )}
        <Box sx={{ p: "2px" }}>
          {noStyle ? "" : "#"}
          {children}
        </Box>
      </Box>
    </Tooltip>
  );
}

export function copyTextToClipboard(text: string) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(
    function () {
      console.log("");
    },
    function (err) {
      console.error("Async: Could not copy text: ", err);
    }
  );
}

function fallbackCopyTextToClipboard(text: string) {
  const textArea = document.createElement("textarea");
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand("copy");
    const msg = successful ? "successful" : "unsuccessful";
  } catch (err) {
    console.error("Fallback: Oops, unable to copy", err);
  }

  document.body.removeChild(textArea);
}
