import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { Hook_Entity } from "../../entities/hook";
import { autopilotApi } from "../api/autopilotApi";

const hooksAdapter = createEntityAdapter<Hook_Entity>();
const initialState = hooksAdapter.getInitialState();

export const hookEndpoints = autopilotApi.injectEndpoints({
  endpoints: (build) => ({
    getHooks: build.query<EntityState<Hook_Entity>, void>({
      query: () => `hooks`,
      transformResponse: (responseData: Hook_Entity[]) => {
        return hooksAdapter.setAll(initialState, responseData);
      },
      providesTags: ["hooks"],
    }),
    getHook: build.query<Hook_Entity, number>({
      query: (id) => `hooks/${id}`,
      providesTags: (result, error, id) => [{ type: "hooks", id }],
    }),
    createHook: build.mutation<Hook_Entity, Partial<Hook_Entity>>({
      query: (body) => ({
        method: "POST",
        body,
        url: `hooks`,
      }),
      invalidatesTags: ["hooks"],
    }),
    updateHook: build.mutation<
      void,
      { id: number; body: Partial<Hook_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `hooks/${args.id}`,
      }),
      invalidatesTags: ["hooks"],
    }),
    deleteHook: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `hooks/${id}`,
      }),
      invalidatesTags: ["hooks"],
    }),
  }),
});

export const {
  useGetHookQuery,
  useGetHooksQuery,
  useCreateHookMutation,
  useDeleteHookMutation,
  useUpdateHookMutation,
} = hookEndpoints;
