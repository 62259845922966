import { Actionnable } from "../actionnable";
import { Option } from "../option";
import { Trigger } from "../trigger";

/**
 * Hook_Entity type guard.
 *
 * @param {any} hookJson Hook object from API
 * @returns {boolean} Return true if type is Hook_Entity
 */
function validator(hookJson: unknown): hookJson is Hook_Entity {
  if (typeof hookJson === "object" && hookJson != null) {
    Hook_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(hookJson).includes(element))
        console.log("Field ${element} is null or undefined");
    });
  }
  return true;
}

export default class Hook_Entity {
  static requiredFields = [
    "id",
    "name",
    "description",
    "triggers",
    "options",
    "actions",
    "createdAt",
    "updatedAt",
  ];

  constructor(hookJson: unknown) {
    if (validator(hookJson)) {
      this.id = hookJson.id;
      this.name = hookJson.name;
      this.description = hookJson.description;
      this.triggers = hookJson.triggers;
      this.options = hookJson.options;
      this.actions = hookJson.actions;
      this.createdAt = hookJson.createdAt;
      this.updatedAt = hookJson.updatedAt;
    } else {
      throw new Error(
        "Failed to create new instance of ${ Hook_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  name: string;
  description: string;
  triggers: Array<Trigger>;
  actions: Array<Actionnable>;
  options: Array<Option>;
  createdAt: string;
  updatedAt: string;
}
