import Instrument_Entity from "./instrument";
export default class Instrument extends Instrument_Entity {
  static fromList(instrumentsJSON: unknown): Array<Instrument> {
    const instruments: Instrument[] = [];
    if (instrumentsJSON)
      Array.isArray(instrumentsJSON) &&
        instrumentsJSON.forEach((instrumentJSON) => {
          instruments.push(new Instrument(instrumentJSON));
        });
    return instruments;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
