import Configuration_Entity from "./configuration";
export default class Configuration extends Configuration_Entity {
  static fromList(configurationsJSON: unknown): Array<Configuration> {
    const configurations: Configuration[] = [];
    if (configurationsJSON)
      Array.isArray(configurationsJSON) &&
        configurationsJSON.forEach((configurationJSON) => {
          configurations.push(new Configuration(configurationJSON));
        });
    return configurations;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
