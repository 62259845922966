import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { autopilotApi } from "../api/autopilotApi";
import { Actionnable_Entity } from "../../entities/actionnable";

const actionsAdapter = createEntityAdapter<Actionnable_Entity>();
const initialState = actionsAdapter.getInitialState();

export const actionEndpoints = autopilotApi.injectEndpoints({
  endpoints: (build) => ({
    getActions: build.query<EntityState<Actionnable_Entity>, void>({
      query: () => `actions`,
      transformResponse: (responseData: Actionnable_Entity[]) => {
        return actionsAdapter.setAll(initialState, responseData);
      },
      providesTags: ["actions"],
    }),
    getAction: build.query<Actionnable_Entity, number>({
      query: (id) => `actions/${id}`,
      providesTags: (result, error, id) => [{ type: "actions", id }],
    }),
    createAction: build.mutation<
      Actionnable_Entity,
      Partial<Actionnable_Entity>
    >({
      query: (body) => ({
        method: "POST",
        body,
        url: `actions`,
      }),
      invalidatesTags: ["actions"],
    }),
    updateAction: build.mutation<
      void,
      { id: number; body: Partial<Actionnable_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `actions/${args.id}`,
      }),
      invalidatesTags: ["actions"],
    }),
    deleteAction: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `actions/${id}`,
      }),
      invalidatesTags: ["actions"],
    }),
  }),
});

export const {
  useGetActionQuery,
  useGetActionsQuery,
  useCreateActionMutation,
  useDeleteActionMutation,
  useUpdateActionMutation,
} = actionEndpoints;
