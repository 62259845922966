import { useSelector } from "react-redux";
import { formOpenSelector } from "../../reducers/rhapsody";
import { InstrumentForm } from "../instrumentForm/instrumentForm";
import { PipelineForm } from "../pipelineForm/pipelineForm";
import { SectionForm } from "../sectionForm/sectionForm";
import { FamilyForm } from "../familyForm/familyForm";
import { TestPipeline } from "../testPipeline/testPipeline";
import { NoteTypeForm } from "../noteTypeForm/noteTypeForm";

/**
 *
 * @returns {ReactElement} CreateCenter page
 */
export function CreateCenter() {
  const pipelineOpen = useSelector(formOpenSelector("pipeline"));
  const instrumentOpen = useSelector(formOpenSelector("instrument"));
  const sectionOpen = useSelector(formOpenSelector("section"));
  const noteTypeOpen = useSelector(formOpenSelector("noteType"));
  const familyOpen = useSelector(formOpenSelector("family"));
  const testPipeline = useSelector(formOpenSelector("testPipeline"));
  return (
    <>
      {pipelineOpen ? <PipelineForm /> : []}
      {instrumentOpen ? <InstrumentForm /> : []}
      {sectionOpen ? <SectionForm /> : []}
      {familyOpen ? <FamilyForm /> : []}
      {noteTypeOpen ? <NoteTypeForm /> : []}
      {testPipeline ? <TestPipeline /> : []}
    </>
  );
}
