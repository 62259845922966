import { Box, Chip, Tooltip } from "@mui/joy";
import { ClickAwayListener } from "@mui/material";
// import PropTypes from 'prop-types';
// material-ui
import React from "react";
import { SketchPicker } from "react-color";
// styles

export default function ColorPicker({
  onChange,
  label,
  color,
  disabled,
}: {
  onChange: (c: string) => void;
  label?: string;
  color?: string;
  disabled?: boolean;
}) {
  const [open, setOpen] = React.useState(false);
  const [col, setCol] = React.useState(
    color && color.length && color !== "none" ? color : "#000000"
  );

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <Tooltip
        open={open}
        arrow
        variant="outlined"
        sx={{ p: 0, overflow: "hidden", borderRadius: 8 }}
        title={
          <ClickAwayListener onClickAway={handleClose}>
            <Box>
              <SketchPicker
                color={col}
                onChangeComplete={(c) => onChange(c.hex)}
                onChange={(c) => {
                  setCol(c.hex);
                }}
              />
            </Box>
          </ClickAwayListener>
        }
      >
        <Box>
          <Chip
            onClick={() => setOpen(true)}
            startDecorator={label}
            sx={{ background: "white" }}
            color="neutral"
            variant="outlined"
            endDecorator={
              <Box
                sx={{
                  height: 14,
                  width: 14,
                  cursor: "pointer",
                  borderRadius: 14,
                }}
                style={{ background: col }}
              />
            }
          >
            {color}
          </Chip>
        </Box>
      </Tooltip>
    </Box>
  );
}
