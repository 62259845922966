import { Button, Input, Typography } from "@mui/joy";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Family_Entity } from "../../entities/family";
import {
  formOpenSelector,
  selectedFamilyIDSelector,
  setFormOpen,
  setSelectedFamilyID,
} from "../../reducers/rhapsody";
import {
  useCreateFamilyMutation,
  useUpdateFamilyMutation,
} from "../../redux/family/familyEndpoints";
import { useFamily } from "../../redux/family/familyHooks";
import DialogClose from "../dialogClose/dialogClose";

/**
 *
 * @returns {ReactElement} FamilyForm page
 */
export function FamilyForm() {
  const open = useSelector(formOpenSelector("family"));
  const dispatch = useDispatch();
  const selectedFamilyID = useSelector(selectedFamilyIDSelector);
  const { family } = useFamily(selectedFamilyID);
  const [createFamily, { isLoading: l1 }] = useCreateFamilyMutation();
  const [updateFamily, { isLoading: l2 }] = useUpdateFamilyMutation();
  const [_family, setFamily] = useState<Partial<Family_Entity>>({
    name: "",
    pos: 1,
  });

  useEffect(() => {
    if (family?.id) setFamily(family);
  }, [family]);

  const close = () => {
    dispatch(setFormOpen({ isOpen: false, formID: "family" }));
    dispatch(setSelectedFamilyID(null));
  };

  const save = () => {
    if (_family.id) {
      updateFamily({ id: _family.id, body: _family });
    } else {
      createFamily(_family);
    }
    close();
  };

  return (
    <DialogClose open={open} onClose={close}>
      <DialogTitle>
        <Typography
          level="title-lg"
          startDecorator={<i className="fa-regular fa-layer-group"></i>}
        >
          {_family?.name ? _family?.name : "New Family"}
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ display: "flex", gap: 1, flexDirection: "column" }}>
        <Input
          value={_family.name}
          onChange={(e) => setFamily((p) => ({ ...p, name: e.target.value }))}
          autoFocus
          startDecorator="Name:"
          sx={{ boxShadow: "none" }}
        />
        <Input
          value={_family.pos}
          type="number"
          onChange={(e) =>
            setFamily((p) => ({ ...p, pos: parseInt(e.target.value) }))
          }
          startDecorator="Position:"
          sx={{ boxShadow: "none" }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="soft" color="neutral" onClick={close}>
          Close
        </Button>
        <Button loading={l1 || l2} onClick={save} disabled={!_family.name}>
          {selectedFamilyID ? "Save" : "Create"}
        </Button>
      </DialogActions>
    </DialogClose>
  );
}
