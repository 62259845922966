import { Button, Typography } from "@mui/joy";
import { Box } from "@mui/system";
import { ReactElement } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import Sections from "../hooks/sections/sections";
import { setFormOpen } from "../reducers/rhapsody";

/**
 *
 * @returns {ReactElement} Nerd route
 */
export default function SectionsPage(): ReactElement {
  const dispatch = useDispatch();

  return (
    <Box
      sx={{
        p: 2,
        display: "flex",
        gap: 2,
        flexDirection: "column",
        maxWidth: 1600,
        marginLeft: "auto",
        marginRight: "auto",
        width: "100%",
      }}
    >
      <Helmet>
        <title>Rhapsody | Sections</title>
      </Helmet>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography sx={{ fontWeight: 800, fontSize: 24 }} level="h4">
          Sections
        </Typography>
        <Button
          onClick={() =>
            dispatch(setFormOpen({ isOpen: true, formID: "section" }))
          }
          size="sm"
        >
          + Add Section
        </Button>
      </Box>
      <Sections />
    </Box>
  );
}
