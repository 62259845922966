/**
 * SectionInstrument_Entity type guard.
 *
 * @param {any} sectionInstrumentJson SectionInstrument object from API
 * @returns {boolean} Return true if type is SectionInstrument_Entity
 */
function validator(
  sectionInstrumentJson: unknown
): sectionInstrumentJson is SectionInstrument_Entity {
  if (
    typeof sectionInstrumentJson === "object" &&
    sectionInstrumentJson != null
  ) {
    SectionInstrument_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(sectionInstrumentJson).includes(element))
        throw new Error("Field ${element} is null or undefined");
    });
  }
  return true;
}

export default class SectionInstrument_Entity {
  static requiredFields = [];

  constructor(sectionInstrumentJson: unknown) {
    if (validator(sectionInstrumentJson)) {
      this.id = sectionInstrumentJson.id;
      this.sectionID = sectionInstrumentJson.sectionID;
      this.instrumentID = sectionInstrumentJson.instrumentID;
    } else {
      throw new Error(
        "Failed to create new instance of ${ SectionInstrument_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  sectionID?: number;
  instrumentID?: number;
}
