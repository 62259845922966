import { useMemo } from "react";
import { Hook } from "../../entities/hook";
import { useGetHookQuery, useGetHooksQuery } from "./hookEndpoints";

export function useHook(
  hookID: number | undefined | null,
  opt?: { skip?: boolean }
) {
  const hookQuery = useGetHookQuery(hookID ?? 0, {
    skip: !hookID || opt?.skip,
  });
  const hookEntity = hookQuery.data;

  return useMemo(() => {
    const ret: typeof hookQuery & {
      hook?: Hook | undefined;
    } = {
      ...hookQuery,
    };
    if (hookEntity) ret.hook = new Hook(hookEntity);
    return ret;
  }, [hookEntity]);
}

export function useHooks() {
  const hookQuery = useGetHooksQuery();
  const hookEntitiesMap = hookQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof hookQuery & {
      hooks?: Hook[];
      hooksMap?: { [id: number]: Hook };
    } = {
      ...hookQuery,
      hooks: [],
      hooksMap: {},
    };
    if (hookEntitiesMap) {
      const hooks: Hook[] = [];
      const hooksMap: { [id: number]: Hook } = {};

      for (const key in hookEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(hookEntitiesMap, key)) {
          const c = hookEntitiesMap[key];
          if (c) {
            const hook = new Hook(c);
            hooks.push(hook);
            if (hook?.id) hooksMap[hook.id] = hook;
          }
        }
      }
      ret.hooks = hooks;
      ret.hooksMap = hooksMap;
    }

    return ret;
  }, [hookEntitiesMap]);
}
