import { Hook } from "../hook";

/**
 * Configuration_Entity type guard.
 *
 * @param {any} configurationJson Configuration object from API
 * @returns {boolean} Return true if type is Configuration_Entity
 */
function validator(
  configurationJson: unknown
): configurationJson is Configuration_Entity {
  if (typeof configurationJson === "object" && configurationJson != null) {
    Configuration_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(configurationJson).includes(element))
        console.log("Field ${element} is null or undefined");
    });
  }
  return true;
}

export default class Configuration_Entity {
  static requiredFields = [
    "id",
    "uuid",
    "projectID",
    "enabled",
    "hooks",
    "createdAt",
    "updatedAt",
  ];

  constructor(configurationJson: unknown) {
    if (validator(configurationJson)) {
      this.id = configurationJson.id;
      this.uuid = configurationJson.uuid;
      this.projectID = configurationJson.projectID;
      this.enabled = configurationJson.enabled;
      this.hooks = configurationJson.hooks;
      this.createdAt = configurationJson.createdAt;
      this.updatedAt = configurationJson.updatedAt;
    } else {
      throw new Error(
        "Failed to create new instance of ${ Configuration_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  uuid: string;
  projectID: number;
  enabled: boolean;
  hooks: Array<Hook>;
  createdAt: string;
  updatedAt: string;
}
