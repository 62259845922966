import { Close } from "@mui/icons-material";
import { IconButton, useTheme } from "@mui/joy";
import { Dialog, DialogProps, useMediaQuery } from "@mui/material";

export default function DialogClose(props: DialogProps) {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down(600));

  return (
    <Dialog
      {...props}
      onClose={props.onClose}
      fullScreen={sm || props.fullScreen}
    >
      {props.onClose ? (
        <div
          style={{
            position: "fixed",
            top: 10,
            right: 10,
            zIndex: 999,
          }}
        >
          <IconButton
            onClick={(e) => {
              if (props?.onClose) props?.onClose(e, "backdropClick");
            }}
            size="sm"
            color="neutral"
            sx={{ color: !sm ? "white" : undefined }}
          >
            <Close sx={{ fontSize: 28 }} />
          </IconButton>
        </div>
      ) : (
        []
      )}
      {props.children}
    </Dialog>
  );
}
