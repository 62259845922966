import Stage_Entity from "./stage";
export default class Stage extends Stage_Entity {
  static fromList(stagesJSON: unknown): Array<Stage> {
    const stages: Stage[] = [];
    if (stagesJSON)
      Array.isArray(stagesJSON) &&
        stagesJSON.forEach((stageJSON) => {
          stages.push(new Stage(stageJSON));
        });
    return stages;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
