import { unityApi } from "../api/unity";

export const unityEndpoints = unityApi.injectEndpoints({
  endpoints: (builder) => ({
    uploadFile: builder.mutation<void, File>({
      query: (file) => {
        const formData = new FormData();
        formData.append("file", file);

        return {
          url: "uploads",
          method: "POST",
          body: formData,
          // headers: {
          //   "Content-Type": "multipart/form-data",
          // },
        };
      },
    }),
  }),
});

export const { useUploadFileMutation } = unityEndpoints;
